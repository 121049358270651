export const ENABLE = "ENABLE";
export const DISABLE = "DISABLE";

export const enable = () => ({
    type: ENABLE,
});

export const disable = () => ({
    type: DISABLE,
});
