export const UploadAction = {
    UpdateUploadProgress: "UPDATE_UPLOAD_PROGRESS",
    UploadSuccess: "UPLOAD_SUCCESS",
    UploadFailed: "UPLOAD_FAILED",
    RefetchFiles: "REFETCH_FILES",
    ClearUploadNotification: "CLEAN_UPLOAD_NOTIFICATION",
    FilterFiles: "GET_FILTERED_FILES",
    CancelUpload: "CANCEL_UPLOAD",
    CancelAllUpload: "CANCEL_ALL_UPLOAD",
    UpdateStatusCount: "UPDATE_STATUS_COUNT",
    UpdateScrollTopVisible: "UPDATE_SCROLL_TOP_VISIBLE",
    retryAllFailedUploads: "RETRY_ALL_FAILED_UPLOADS",
    SetUploadNotiTab: "SET_UPLOAD_NOTI_TAB",
    RetryStarted: "RETRY_STARTED",
    RetryAllUploadStarted: "RETRY_ALL_UPLOAD_STARTED",
    UpdateNotificationVisibilty: "UPDATE_NOTIFICATION_VISIBILITY",
};

export const updateUploadProgress = (data, dispatch) => {
    dispatch({
        type: UploadAction.UpdateUploadProgress,
        payload: data,
    });
};

export const uploadSuccess = (data, dispatch) => {
    dispatch({
        type: UploadAction.UploadSuccess,
        payload: data,
    });
};

export const uploadFailed = (data, dispatch) => {
    dispatch({
        type: UploadAction.UploadFailed,
        payload: data,
    });
};

export const refetchFiles = (data, dispatch) => {
    dispatch({
        type: UploadAction.RefetchFiles,
        payload: data,
    });
};

export const filterFiles = (data, dispatch) => {
    dispatch({
        type: UploadAction.FilterFiles,
        payload: data,
    });
};

export const clearNotification = (data, dispatch) => {
    dispatch({
        type: UploadAction.ClearUploadNotification,
        payload: data,
    });
};

export const uploadCancelled = (data, dispatch) => {
    dispatch({
        type: UploadAction.CancelUpload,
        payload: data,
    });
};

export const allUploadCancelled = (data, dispatch) => {
    dispatch({
        type: UploadAction.CancelAllUpload,
        payload: data,
    });
};

export const retryStarted = (data, dispatch) => {
    dispatch({
        type: UploadAction.RetryStarted,
        payload: data,
    });
};

export const retryAllUploadStarted = (data, dispatch) => {
    dispatch({
        type: UploadAction.RetryAllUploadStarted,
        payload: data,
    });
};

export const updateStatusCount = (data, dispatch) => {
    dispatch({
        type: UploadAction.UpdateStatusCount,
        payload: data,
    });
};

export const updateScrollTopVisible = (data, dispatch) => {
    dispatch({
        type: UploadAction.UpdateScrollTopVisible,
        payload: data,
    });
};

export const setUploadNotiTab = (data, dispatch) => {
    dispatch({
        type: UploadAction.SetUploadNotiTab,
        payload: data,
    });
};

export const updateNotificationVisibilty = (data, dispatch) => {
    dispatch({
        type: UploadAction.UpdateNotificationVisibilty,
        payload: data,
    });
};
