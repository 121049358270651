import environment from "../../environment";
import store from "../redux/store";
import _ from "lodash";
import * as events from "./events";
import { userFieldsForAnalytics, orgFieldsForAnalytics } from "@common/constants/index";

const generateEventsArray = () => {
    try {
        return Object.values(events) ?? [];
    } catch (error) {
        console.log({ error });
    }
};

export const EventEmitter = {
    events: {},
    eventQueue: {},
    deviceType: {},
    eventsList: generateEventsArray(),
    trackingProperties: {},
    dispatch(event, data) {
        if (!this.events[event]) {
            // If there is no listener for the event, queue the event
            if (!this.eventQueue[event]) this.eventQueue[event] = [];
            this.eventQueue[event].push(data);
            return;
        }
        // Process any queued events for this type
        if (this.eventQueue[event]) {
            this.eventQueue[event].forEach((queuedData) => {
                this.processEvent(event, queuedData);
            });
            delete this.eventQueue[event];
        }
        // Process the current event
        this.processEvent(event, data);
    },

    processEvent(event, data) {
        const state = store.getState();
        this.events?.[event].forEach((callback) => {
            let newData = {
                ...data,
                ...this.trackingProperties,
                user: _.pick(state?.authenticationDetails?.user, userFieldsForAnalytics),
                org: _.pick(state?.organizationDetails?.organization, orgFieldsForAnalytics),
            };
            if (environment.GTM_CONTAINER_ID) callback(event, newData);
        });
    },

    on(event, callback) {
        if (!this.events[event]) this.events[event] = [];
        this.events[event].push(callback);
        // If there are queued events for this type, process them
        if (this.eventQueue[event]) {
            this.eventQueue[event].forEach((queuedData) => {
                this.processEvent(event, queuedData);
            });
            delete this.eventQueue[event];
        }
    },
};

if (typeof window !== "undefined") window.EventEmitter = EventEmitter;
