import _ from "lodash";

import ConsentService from "@src/services/consent.service";
import environment from "@src/environment";
import { LAST_CONSENT_APPROVAL_KEY_PREFIX } from "@common/constants/constants";

const CONSENT_CHECK_INTERVAL = environment.ENV !== "production" ? 0 : 1000 * 60 * 60 * 24 * 7; // 1 week

const getLatestVersions = async () => {
    const latestPrivPolVer = await ConsentService.getLatestPrivacyPolicyVersion();

    const versions = {
        privacyPolicyVer: latestPrivPolVer,
    };
    return versions;
};

const areConsentsUpToDate = (userConsents, latestConsentVersions) => {
    if (userConsents.acceptedPrivacyPolicyVersion !== latestConsentVersions.privacyPolicyVer)
        return false;

    return true;
};

const ConsentGuard = async () => {
    try {
        const location = window.location;
        const redirectTo = location.pathname + location.search + location.hash;

        const res = await ConsentService.getUserConsent(); // Fetches from DB not redis, so no stale data
        const consent = res.data;
        if (!consent) return "";

        /**
         * If user has already consented to the latest version,
         * and we've verified it within the last week, we don't need to check again.
         */
        const lastConsentConfirmation = window.localStorage.getItem(
            LAST_CONSENT_APPROVAL_KEY_PREFIX + consent.userId,
        );

        if (
            lastConsentConfirmation &&
            new Date().getTime() - JSON.parse(lastConsentConfirmation) < CONSENT_CHECK_INTERVAL
        )
            return "";

        const latestConsentVersions = await getLatestVersions();

        if (
            areConsentsUpToDate(
                {
                    acceptedPrivacyPolicyVersion: consent.acceptedPrivacyPolicyVersion,
                },
                latestConsentVersions,
            )
        ) {
            window.localStorage.setItem(
                LAST_CONSENT_APPROVAL_KEY_PREFIX + consent.userId,
                JSON.stringify(new Date().getTime()), // Store as string
            );
            return "";
        }

        return `/consent-approval?redirectTo=${encodeURIComponent(redirectTo)}`;
    } catch (err) {
        // If any error occurs, we don't want to block the user from accessing the app.
        console.error("Error in ConsentGuard", err);
        return "";
    }
};

export default ConsentGuard;
