export const WebsiteConfigAction = {
    SET_WEBSITECONFIG: "SET_WEBSITECONFIG",
};

export const setWebsiteConfig = (data, dispatch) => {
    dispatch({
        type: WebsiteConfigAction.SET_WEBSITECONFIG,
        payload: data,
    });
};
