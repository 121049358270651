export const GlamarModuleActions = {
    updateFormData: "UPDATE_FORM_DATA",
    addDefaultFormData: "ADD_DEFAULT_FORM_DATA",
    setFormBuilderJson: "SET_FORM_BUILDER_JSON",
    setUploadSuccess: "SET_UPLOAD_SUCCESS",
    setUploadFailed: "SET_UPLOAD_FAILED",
    setProductData: "SET_PRODUCT_DATA",
    setPageNumber: "SET_PAGE_NUMBER",
    setPageSize: "SET_PAGE_SIZE",
    setSortBy: "SET_SORT_BY",
    setSortOrder: "SET_SORT_ORDER",
    setSearchBy: "SET_SEARCH_BY",
    setSearchQuery: "SET_SEARCH_QUERY",
    setPageDetails: "SET_PAGE_DETAILS",
    setClusteredPaginationData: "SET_CLUSTERED_PAGINATION_DATA",
    setSkuToBeEdited: "SET_SKU_TO_BE_EDITED",
    setFormData: "SET_FORM_DATA",
    startSkuBulkUpload: "START_SKU_BULK_UPLOAD",
    setFileParsingSuccess: "SET_FILE_PARSING_SUCCESS",
    setErrorWhileFileParsing: "SET_ERROR_WHILE_FILE_PARSING",
    setEachBulkSkuSuccess: "SET_EACH_BULK_SKU_SUCCESS",
    setEachBulkSkuFailure: "SET_EACH_BULK_SKU_FAILURE",
    resetSkuBulkUpload: "RESET_SKU_BULK_UPLOAD",
    setEachRetrySuccess: "SET_EACH_RETRY_SUCCESS",
    setBulkDeleteTotalSkus: "SET_BULK_DELETE_TOTAL_SKUS",
    setBulkNotificationPanelVisibility: "SET_BULK_NOTIFICATION_PANEL_VISIBILITY",
    setIsDeletingFilesFlag: "SET_IS_DELETING_FILES_FLAG",

    setShowMobileBulkDeleteCheckbox: "SET_SHOW_MOBILE_BULK_DELETE_CHECKBOX",
    setShowCreditsExhaustedBanner: "SET_SHOW_CREDITS_EXHAUSTED_BANNER",
};

export const updateFormData = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.updateFormData,
        payload: data,
    });
};
export const addDefaultFormData = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.addDefaultFormData,
        payload: {},
    });
};

export const setFormData = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setFormData,
        payload: data,
    });
};

export const setFormBuilderJson = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setFormBuilderJson,
        payload: {},
    });
};

export const setUploadSuccess = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setUploadSuccess,
        payload: data,
    });
};

export const setUploadFailed = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setUploadFailed,
        payload: data,
    });
};

export const setProductData = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setProductData,
        payload: data,
    });
};

export const setPageNumber = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setPageNumber,
        payload: data,
    });
};

export const setPageSize = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setPageSize,
        payload: data,
    });
};

export const setSortBy = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setSortBy,
        payload: data,
    });
};

export const setSortOrder = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setSortOrder,
        payload: data,
    });
};

export const setSearchBy = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setSearchBy,
        payload: data,
    });
};

export const setSearchQuery = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setSearchQuery,
        payload: data,
    });
};

export const setPageDetails = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setPageDetails,
        payload: data,
    });
};

export const setClusteredPaginationData = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setClusteredPaginationData,
        payload: data,
    });
};

export const setSkuToBeEdited = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setSkuToBeEdited,
        payload: data,
    });
};

export const startSkuBulkUpload = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.startSkuBulkUpload,
        payload: data,
    });
};

export const setFileParsingSuccess = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setFileParsingSuccess,
        payload: data,
    });
};
export const setErrorWhileFileParsing = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setErrorWhileFileParsing,
        payload: data,
    });
};
export const setEachBulkSkuSuccess = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setEachBulkSkuSuccess,
        payload: data,
    });
};

export const setEachBulkSkuFailure = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setEachBulkSkuFailure,
        payload: data,
    });
};

export const resetBulkUploadData = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.resetSkuBulkUpload,
        payload: data,
    });
};
export const setEachRetrySuccess = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setEachRetrySuccess,
        payload: data,
    });
};
export const setBulkDeleteTotalSkus = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setBulkDeleteTotalSkus,
        payload: data,
    });
};

export const setBulkNotificationPanelVisibility = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setBulkNotificationPanelVisibility,
        payload: data,
    });
};

export const setIsDeletingFilesFlag = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setIsDeletingFilesFlag,
        payload: data,
    });
};

export const setShowMobileBulkDeleteCheckbox = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setShowMobileBulkDeleteCheckbox,
        payload: data,
    });
};

export const setShowCreditsExhaustedBanner = (data, dispatch) => {
    dispatch({
        type: GlamarModuleActions.setShowCreditsExhaustedBanner,
        payload: data,
    });
};
