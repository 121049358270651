import { DomainAction } from "../actions/domainActions";

const initialState = { domains: {}, searchResults: null, pages: {} };

const domainReducer = (state = initialState, action) => {
    const { type, payload } = action;
    // const newState = { ...state };

    switch (type) {
        case DomainAction.GetDomains:
            return {
                ...state,
                ...payload,
            };
        case DomainAction.DomainCreateSuccess:
            // zone = payload.zone;
            // newState.zoneList.zones[zone._id] = zone;
            return {
                ...state,
                // ...newState,
            };
        case DomainAction.DomainCreateFailure:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default domainReducer;
