import { selectionAction } from "../actions/selectionAction";

const initialState = {
    allItems: [],
    multiSelectedItems: new Map(),
    startingPoint: -1,
    delimeter: -1,
};

// will create a new map with new values
function setNewMap(oldMap, keyValuePairs) {
    const newMultiSelectedItems = new Map();

    for (const [key, value] of [...oldMap, ...keyValuePairs]) {
        newMultiSelectedItems.set(key, value);
    }

    return newMultiSelectedItems;
}

// when nothing is selected and keypress event fires then this function is invoked
const firstTimeShiftKeyIsPressed = (state) => {
    if (state.allItems.length) {
        return {
            ...state,
            startingPoint: 0,
            delimeter: 0,
            multiSelectedItems: setNewMap(state.multiSelectedItems, [
                [state.allItems[0]._id, state.allItems[0]],
            ]),
        };
    } else {
        return state;
    }
};

const changeSelectedMapForShiftUp = (allItems, selectedMap, start, end, append) => {
    if (append) {
        const appendSelectedItems = setNewMap(selectedMap, []);
        for (let i = start; i >= end; i--) {
            appendSelectedItems.set(allItems[i]._id, allItems[i]);
        }
        return appendSelectedItems;
    } else {
        const copy = new Map();
        const multiSelectedItemKeys = [...selectedMap.keys()];
        for (let i = 0; i < end; i++) {
            const value = selectedMap.get(multiSelectedItemKeys[i]);
            copy.set(multiSelectedItemKeys[i], value);
        }
        return copy;
    }
};

const changeSelectedMapForShiftDown = (allItems, selectedMap, start, end, append) => {
    if (append) {
        const appendSelectedItems = setNewMap(selectedMap, []);
        for (let i = start; i <= end; i++) {
            appendSelectedItems.set(allItems[i]._id, allItems[i]);
        }
        return appendSelectedItems;
    } else {
        const copy = new Map();
        const multiSelectedItemKeys = [...selectedMap.keys()];
        for (let i = 0; i < end; i++) {
            const value = selectedMap.get(multiSelectedItemKeys[i]);
            copy.set(multiSelectedItemKeys[i], value);
        }
        return copy;
    }
};

// change delimeter range for left and right key press
const changeDelimeter = (allItems, selectedMap, range, keyLeft) => {
    if (keyLeft) {
        let fill;
        const lastId = Array.from(selectedMap.keys()).pop();
        fill = range - 1 >= 0 ? range - 1 : range;
        if (!selectedMap.get(allItems[fill]._id)) {
            fill = allItems.findIndex((element) => element._id === lastId) - 1;
        }
        return { fill, lastId };
    } else {
        let fill;
        const lastId = Array.from(selectedMap.keys()).pop();
        fill = range + 1 < allItems.length ? range + 1 : range;
        if (!selectedMap.get(allItems[fill]._id)) {
            fill = allItems.findIndex((element) => element._id === lastId) + 1;
        }
        return { fill, lastId };
    }
};

export const selectionReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        // fetch all the items to be selected for the current page
        case selectionAction.FetchAllItems:
            return {
                ...state,
                allItems: payload,
            };

        case selectionAction.ShiftLeft: {
            if (state.multiSelectedItems.size) {
                // when the selection starts from the first element
                if (state.startingPoint === 0) {
                    const { fill, lastId } = changeDelimeter(
                        state.allItems,
                        state.multiSelectedItems,
                        state.delimeter,
                        true,
                    );
                    const lastIndex = state.allItems.findIndex((item) => item._id === lastId);
                    const copy = new Map(state.multiSelectedItems);
                    state.startingPoint !== lastIndex && copy.delete(lastId);
                    return {
                        ...state,
                        delimeter: fill,
                        multiSelectedItems: copy,
                    };
                } else if (state.startingPoint === state.allItems.length - 1) {
                    // when selection starts from the last element
                    const { fill, lastId } = changeDelimeter(
                        state.allItems,
                        state.multiSelectedItems,
                        state.delimeter,
                        true,
                    );
                    return {
                        ...state,
                        delimeter: fill,
                        multiSelectedItems: setNewMap(state.multiSelectedItems, [
                            [state.allItems[fill]._id, state.allItems[fill]],
                        ]),
                    };
                } else {
                    // when selection starts from the middle element
                    if (state.delimeter === state.startingPoint) {
                        const { fill, lastId } = changeDelimeter(
                            state.allItems,
                            state.multiSelectedItems,
                            state.delimeter,
                            true,
                        );
                        return {
                            ...state,
                            delimeter: fill,
                            multiSelectedItems: setNewMap(state.multiSelectedItems, [
                                [state.allItems[fill]._id, state.allItems[fill]],
                            ]),
                        };
                    } else {
                        if (state.delimeter < state.startingPoint) {
                            const { fill, lastId } = changeDelimeter(
                                state.allItems,
                                state.multiSelectedItems,
                                state.delimeter,
                                true,
                            );
                            const element = state.allItems[fill]._id;
                            if (
                                ![...state.multiSelectedItems.keys()].find(
                                    (elementId) => elementId === element,
                                )
                            ) {
                                return {
                                    ...state,
                                    delimeter: fill,
                                    multiSelectedItems: setNewMap(state.multiSelectedItems, [
                                        [state.allItems[fill]._id, state.allItems[fill]],
                                    ]),
                                };
                            } else return state;
                        } else {
                            const { fill, lastId } = changeDelimeter(
                                state.allItems,
                                state.multiSelectedItems,
                                state.delimeter,
                                true,
                            );
                            const lastIndex = state.allItems.findIndex(
                                (item) => item._id === lastId,
                            );
                            const copy = new Map(state.multiSelectedItems);
                            state.startingPoint !== lastIndex && copy.delete(lastId);
                            return {
                                ...state,
                                delimeter: fill,
                                multiSelectedItems: copy,
                            };
                        }
                    }
                }
            } else {
                return firstTimeShiftKeyIsPressed(state);
            }
        }

        case selectionAction.ShiftUp: {
            if (state.multiSelectedItems.size) {
                // when the selection starts from the first element
                if (state.startingPoint === 0) {
                    if (state.multiSelectedItems.size > 5) {
                        const copy = new Map();
                        const multiSelectedItemKeys = [...state.multiSelectedItems.keys()];
                        for (let i = 0; i < state.multiSelectedItems.size - 5; i++) {
                            const value = state.multiSelectedItems.get(multiSelectedItemKeys[i]);
                            copy.set(multiSelectedItemKeys[i], value);
                        }

                        const fill =
                            state.delimeter - 5 >= 0 ? state.delimeter - 5 : state.delimeter;
                        return {
                            ...state,
                            delimeter: fill,
                            multiSelectedItems: copy,
                        };
                    } else {
                        return state;
                    }
                } else if (state.startingPoint === state.allItems.length - 1) {
                    // when selection starts from the last element
                    const fill = state.delimeter - 5 >= 0 ? state.delimeter - 5 : 0;
                    const newSelectedMap = changeSelectedMapForShiftUp(
                        state.allItems,
                        state.multiSelectedItems,
                        state.delimeter - 1,
                        fill,
                        true,
                    );

                    return {
                        ...state,
                        delimeter: fill,
                        multiSelectedItems: newSelectedMap,
                    };
                } else {
                    // when selection starts from the middle element
                    if (state.delimeter === state.startingPoint) {
                        if (state.delimeter - 5 >= 0) {
                            const fill = state.delimeter - 5 >= 0 ? state.delimeter - 5 : 0;
                            const newSelectedMap = changeSelectedMapForShiftUp(
                                state.allItems,
                                state.multiSelectedItems,
                                state.delimeter - 1,
                                fill,
                                true,
                            );
                            return {
                                ...state,
                                delimeter: fill,
                                multiSelectedItems: newSelectedMap,
                            };
                        } else {
                            const newSelectedMap = changeSelectedMapForShiftUp(
                                state.allItems,
                                state.multiSelectedItems,
                                state.delimeter - 1,
                                0,
                                true,
                            );
                            return {
                                ...state,
                                delimeter: 0,
                                multiSelectedItems: newSelectedMap,
                            };
                        }
                    } else {
                        if (state.delimeter < state.startingPoint) {
                            const fill = state.delimeter - 5 >= 0 ? state.delimeter - 5 : 0;
                            const newSelectedMap = changeSelectedMapForShiftUp(
                                state.allItems,
                                state.multiSelectedItems,
                                state.delimeter - 1,
                                fill,
                                true,
                            );
                            return {
                                ...state,
                                delimeter: fill,
                                multiSelectedItems: newSelectedMap,
                            };
                        } else {
                            if (state.multiSelectedItems.size > 5) {
                                const fill =
                                    state.delimeter - 5 >= 0
                                        ? state.delimeter - 5
                                        : state.delimeter;
                                const newSelectedMap = changeSelectedMapForShiftUp(
                                    state.allItems,
                                    state.multiSelectedItems,
                                    0,
                                    state.multiSelectedItems.size - 5,
                                    false,
                                );
                                return {
                                    ...state,
                                    delimeter: fill,
                                    multiSelectedItems: newSelectedMap,
                                };
                            } else {
                                const newDelimeter = state.startingPoint;
                                const fill = newDelimeter - 5 >= 0 ? newDelimeter - 5 : 0;
                                const copy = new Map();
                                const multiSelectedItemKeys = [...state.multiSelectedItems.keys()];
                                const value = state.multiSelectedItems.get(
                                    multiSelectedItemKeys[0],
                                );
                                copy.set(multiSelectedItemKeys[0], value);
                                for (let i = newDelimeter - 1; i >= fill; i--) {
                                    copy.set(state.allItems[i]._id, state.allItems[i]);
                                }
                                return {
                                    ...state,
                                    delimeter: fill,
                                    multiSelectedItems: copy,
                                };
                            }
                        }
                    }
                }
            } else {
                return firstTimeShiftKeyIsPressed(state);
            }
        }

        case selectionAction.ShiftRight: {
            if (state.multiSelectedItems.size) {
                // when the selection starts from the first element
                if (state.startingPoint === 0) {
                    const { fill, lastId } = changeDelimeter(
                        state.allItems,
                        state.multiSelectedItems,
                        state.delimeter,
                        false,
                    );
                    return {
                        ...state,
                        delimeter: fill,
                        multiSelectedItems: setNewMap(state.multiSelectedItems, [
                            [state.allItems[fill]._id, state.allItems[fill]],
                        ]),
                    };
                } else if (state.startingPoint === state.allItems.length - 1) {
                    // when selection starts from the last element
                    const { fill, lastId } = changeDelimeter(
                        state.allItems,
                        state.multiSelectedItems,
                        state.delimeter,
                        false,
                    );
                    const lastIndex = state.allItems.findIndex((item) => item._id === lastId);
                    const copy = new Map(state.multiSelectedItems);
                    state.startingPoint !== lastIndex && copy.delete(lastId);
                    return {
                        ...state,
                        delimeter: fill,
                        multiSelectedItems: copy,
                    };
                } else {
                    // when selection starts from the middle element
                    if (state.delimeter === state.startingPoint) {
                        const { fill, lastId } = changeDelimeter(
                            state.allItems,
                            state.multiSelectedItems,
                            state.delimeter,
                            false,
                        );
                        return {
                            ...state,
                            delimeter: fill,
                            multiSelectedItems: setNewMap(state.multiSelectedItems, [
                                [state.allItems[fill]._id, state.allItems[fill]],
                            ]),
                        };
                    } else {
                        if (state.delimeter < state.startingPoint) {
                            const { fill, lastId } = changeDelimeter(
                                state.allItems,
                                state.multiSelectedItems,
                                state.delimeter,
                                false,
                            );
                            const lastIndex = state.allItems.findIndex(
                                (item) => item._id === lastId,
                            );
                            const copy = new Map(state.multiSelectedItems);
                            state.startingPoint !== lastIndex && copy.delete(lastId);
                            return {
                                ...state,
                                delimeter: fill,
                                multiSelectedItems: copy,
                            };
                        } else {
                            const { fill, lastId } = changeDelimeter(
                                state.allItems,
                                state.multiSelectedItems,
                                state.delimeter,
                                false,
                            );
                            const element = state.allItems[fill]._id;
                            if (
                                ![...state.multiSelectedItems.keys()].find(
                                    (elementId) => elementId === element,
                                )
                            ) {
                                return {
                                    ...state,
                                    delimeter: fill,
                                    multiSelectedItems: setNewMap(state.multiSelectedItems, [
                                        [state.allItems[fill]._id, state.allItems[fill]],
                                    ]),
                                };
                            } else return state;
                        }
                    }
                }
            } else {
                return firstTimeShiftKeyIsPressed(state);
            }
        }

        case selectionAction.ShiftDown: {
            if (state.multiSelectedItems.size) {
                // when the selection starts from the first element
                if (state.startingPoint === 0) {
                    const fill =
                        state.delimeter + 5 < state.allItems.length
                            ? state.delimeter + 5
                            : state.allItems.length - 1;
                    const newSelectedMap = changeSelectedMapForShiftDown(
                        state.allItems,
                        state.multiSelectedItems,
                        state.delimeter + 1,
                        fill,
                        true,
                    );
                    return {
                        ...state,
                        delimeter: fill,
                        multiSelectedItems: newSelectedMap,
                    };
                } else if (state.startingPoint === state.allItems.length - 1) {
                    // when selection starts from the last element
                    if (state.multiSelectedItems.size > 5) {
                        const newSelectedMap = changeSelectedMapForShiftDown(
                            state.allItems,
                            state.multiSelectedItems,
                            0,
                            state.multiSelectedItems.size - 5,
                            false,
                        );
                        const fill =
                            state.delimeter + 5 < state.allItems.length
                                ? state.delimeter + 5
                                : state.delimeter;
                        return {
                            ...state,
                            delimeter: fill,
                            multiSelectedItems: newSelectedMap,
                        };
                    } else {
                        return state;
                    }
                } else {
                    // when selection starts from the middle element
                    if (state.delimeter === state.startingPoint) {
                        if (state.delimeter + 5 < state.allItems.length) {
                            const fill =
                                state.delimeter + 5 < state.allItems.length
                                    ? state.delimeter + 5
                                    : state.allItems.length - 1;
                            const newSelectedMap = changeSelectedMapForShiftDown(
                                state.allItems,
                                state.multiSelectedItems,
                                state.delimeter + 1,
                                fill,
                                true,
                            );
                            return {
                                ...state,
                                delimeter: fill,
                                multiSelectedItems: newSelectedMap,
                            };
                        } else {
                            const newSelectedMap = changeSelectedMapForShiftDown(
                                state.allItems,
                                state.multiSelectedItems,
                                state.delimeter + 1,
                                state.allItems.length - 1,
                                true,
                            );
                            const fill = state.allItems.length - 1;

                            return {
                                ...state,
                                delimeter: fill,
                                multiSelectedItems: newSelectedMap,
                            };
                        }
                    } else {
                        if (state.delimeter < state.startingPoint) {
                            if (state.multiSelectedItems.size > 5) {
                                const fill =
                                    state.delimeter + 5 < state.allItems.length
                                        ? state.delimeter + 5
                                        : state.delimeter;
                                const newSelectedMap = changeSelectedMapForShiftDown(
                                    state.allItems,
                                    state.multiSelectedItems,
                                    0,
                                    state.multiSelectedItems.size - 5,
                                    false,
                                );
                                return {
                                    ...state,
                                    delimeter: fill,
                                    multiSelectedItems: newSelectedMap,
                                };
                            } else {
                                const newDelimeter = state.startingPoint;
                                const fill =
                                    newDelimeter + 5 < state.allItems.length
                                        ? newDelimeter + 5
                                        : state.allItems.length - 1;

                                const copy = new Map();
                                const multiSelectedItemKeys = [...state.multiSelectedItems.keys()];
                                const value = state.multiSelectedItems.get(
                                    multiSelectedItemKeys[0],
                                );
                                copy.set(multiSelectedItemKeys[0], value);

                                for (let i = newDelimeter + 1; i <= fill; i++) {
                                    copy.set(state.allItems[i]._id, state.allItems[i]);
                                }
                                return {
                                    ...state,
                                    delimeter: fill,
                                    multiSelectedItems: copy,
                                };
                            }
                        } else {
                            const fill =
                                state.delimeter + 5 < state.allItems.length
                                    ? state.delimeter + 5
                                    : state.allItems.length - 1;
                            const newSelectedMap = changeSelectedMapForShiftDown(
                                state.allItems,
                                state.multiSelectedItems,
                                state.delimeter + 1,
                                fill,
                                true,
                            );
                            return {
                                ...state,
                                delimeter: fill,
                                multiSelectedItems: newSelectedMap,
                            };
                        }
                    }
                }
            } else {
                return firstTimeShiftKeyIsPressed(state);
            }
        }

        case selectionAction.FetchAllManualSelection: {
            // when selection happens without any key press event
            if (state.multiSelectedItems.size > 2 && state.delimeter === state.startingPoint) {
                const startPoint = state.allItems.findIndex(
                    (item) => item._id === Array.from(state.multiSelectedItems.keys())[0],
                );
                const lastId = Array.from(state.multiSelectedItems.keys()).pop();

                const delimeterChange = state.allItems.findIndex((item) => item._id === lastId);
                return {
                    ...state,
                    startingPoint: startPoint,
                    delimeter: delimeterChange,
                };
            } else {
                let startPoint;
                if (state.startingPoint === -1) {
                    startPoint = state.allItems.findIndex(
                        (item) => item._id === Array.from(payload.keys())[0],
                    );
                } else {
                    startPoint = state.startingPoint;
                }
                const lastId = Array.from(payload.keys()).pop();

                const delimeterChange = state.allItems.findIndex((item) => item._id === lastId);
                return {
                    ...state,
                    startingPoint: startPoint,
                    delimeter: delimeterChange,
                    multiSelectedItems: payload,
                };
            }
        }

        case selectionAction.ClearAllSelected: {
            // clearing all the selected Items
            return {
                ...state,
                multiSelectedItems: new Map(),
                startingPoint: -1,
                delimeter: -1,
            };
        }

        default:
            return state;
        // throw new Error(`Unknown action type: ${type}`);
    }
};
