import React, { useState } from "react";
import styled from "styled-components";
import { SubTitle1 } from "../global-styled-components";
import SearchIcon from "common/assets/SearchIcon";
import { ReactComponent as CancelIcon } from "common/assets/svgs/Cross.svg";
import { Breakpoints } from "../../GlobalStyle";
import CustomLink from "common/components/CustomLink";
import InfoIcon from "common/assets/InfoIcon";

const HeadingWrapper = styled.div`
    display: flex;
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "center")};
    position: relative;
    @media (max-width: ${Breakpoints.mobile}px) {
        background-color: transparent;
        padding: 0;
    }
`;

const Heading = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "space-between")};
    width: 100%;
    max-width: ${({ fullWidth }) => !fullWidth && "1200px"};
    padding: 16px;
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }
`;

const LeftWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 16px;
    position: relative;
    @media (max-width: ${Breakpoints.mobile}px) {
        width: 100%;
        justify-content: space-between;
        background-color: var(--dark-900);
        padding: 8px 16px;
    }
`;

const InputWrapper = styled.div`
    max-width: 500px;
    width: 100%;
    @media (max-width: ${Breakpoints.mobile}px) {
        display: none;
    }
`;

const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    @media (max-width: ${Breakpoints.mobile}px) {
        justify-content: flex-end;
        width: 100%;
        padding: 0 16px;
    }
`;

const IconWrapper = styled.div`
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    height: 40px;
    background: var(--dark-700);
    border-radius: var(--border-radius-8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background: var(--dark-600);
    }
    @media (min-width: ${Breakpoints.mobile}px) {
        display: none;
    }
`;

const OverlaySearch = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--dark-900);
    z-index: 1;
    gap: 20px;
    min-height: 40px;
    @media (max-width: ${Breakpoints.mobile}px) {
        padding: 16px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 20px;
    min-height: 40px;
`;

const ComponentHeader = ({
    children,
    justifyContent,
    fullWidth = false,
    title,
    back,
    search,
    learnMore,
    actions,
    hideSearchIcon = false,
    InfoIconUrl,
    ...props
}) => {
    const [showMobSearch, setShowMobSearch] = useState(false);
    return (
        <HeadingWrapper justifyContent={justifyContent}>
            <Heading fullWidth={fullWidth}>
                <LeftWrapper>
                    <Wrapper>
                        {back && <div>{back}</div>}
                        {title && <SubTitle1>{title}</SubTitle1>}
                        {search && <InputWrapper>{search}</InputWrapper>}
                        {InfoIconUrl && (
                            <CustomLink openInNew={true} href={InfoIconUrl}>
                                <InfoIcon style={{ fill: "var(--light-300)" }} />
                            </CustomLink>
                        )}
                        {children}
                    </Wrapper>
                    {search && (
                        <>
                            {!showMobSearch && (
                                <IconWrapper onClick={() => setShowMobSearch(true)}>
                                    <SearchIcon width="18" height="18" />
                                </IconWrapper>
                            )}
                            {showMobSearch && (
                                <OverlaySearch>
                                    {search}
                                    <IconWrapper onClick={() => setShowMobSearch(false)}>
                                        <CancelIcon />
                                    </IconWrapper>
                                </OverlaySearch>
                            )}
                        </>
                    )}
                </LeftWrapper>
                {actions && <ActionWrapper>{actions}</ActionWrapper>}
            </Heading>
        </HeadingWrapper>
    );
};

export default ComponentHeader;
