export const PaymentAction = {
    UpdateActiveSubscription: "UPDATE_ACTIVE_SUBSCRIPTION",
    UpdateExchangeRates: "UPDATE_EXCHANGE_RATES",
};

export const updateActiveSubscription = (data, dispatch) => {
    dispatch({
        type: PaymentAction.UpdateActiveSubscription,
        payload: data,
    });
};

export const updateExchangeRates = (data, dispatch) => {
    dispatch({
        type: PaymentAction.UpdateExchangeRates,
        payload: { exchangeRates: data },
    });
};
