import DatasourceService from "../../../services/datasource.service";
import _ from "lodash";

export const DatasourceAction = {
    DatasourceCreateSuccess: "CREATE_DATASOURCE_SUCCESS",
    DatasourceCreateFailed: "CREATE_ZONE_FAILED",
    CreateDatasource: "CREATE_DATASOURCE",
    GetDatasources: "GET_DATASOURCES",
    GetDatasource: "GET_DATASOURCE",
    UpdateDatasource: "UPDATE_DATASOURCE",
    DeleteDatasource: "DELETE_DATASOURCE",
    SetRedirectDatasource: "SET_REDIRECT_DATASOURCE",
};

export const createDatasource = (orgId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        DatasourceService.createDatasource(orgId, data)
            .then((res) => {
                dispatch({
                    type: DatasourceAction.DatasourceCreateSuccess,
                    payload: { isZoneRegistered: true, datasource: res.data },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DatasourceAction.DatasourceCreateFailed,
                    payload: { isZoneRegistered: false, datasource: null },
                });
                reject(err);
            });
    });
};

export const getDatasources = (dispatch, orgId, pageNo, pageSize, search) => {
    return new Promise((resolve, reject) => {
        DatasourceService.getDatasources(orgId, pageNo, pageSize, search)
            .then((res) => {
                dispatch({
                    type: DatasourceAction.GetDatasources,
                    payload: {
                        datasourceList: { datasources: res.data.items, page: res.data.page },
                    },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DatasourceAction.GetDatasources,
                    payload: { items: [] },
                });
                reject(err);
            });
    });
};

export const updateDatasource = (orgId, datasourceId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        DatasourceService.updateDatasource(orgId, datasourceId, data)
            .then((res) => {
                dispatch({
                    type: DatasourceAction.UpdateDatasource,
                    payload: { isDatasourceUpdated: true, datasource: res.data },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DatasourceAction.UpdateDatasource,
                    payload: { isDatasourceUpdated: false, datasource: null },
                });
                reject(err);
            });
    });
};

export const getDatasource = (orgId, datasourceId, dispatch) => {
    return new Promise((resolve, reject) => {
        DatasourceService.getDatasource(orgId, datasourceId)
            .then((res) => {
                dispatch({
                    type: DatasourceAction.GetDatasource,
                    payload: { exists: true, datasource: res.data },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DatasourceAction.GetDatasource,
                    payload: { exists: false, datasource: null },
                });
                reject(err);
            });
    });
};

export const deleteDatasource = (dispatch, orgId, datasourceId) => {
    return new Promise((resolve, reject) => {
        DatasourceService.deleteDatasource(orgId, datasourceId)
            .then((res) => {
                dispatch({
                    type: DatasourceAction.DeleteDatasource,
                    payload: { exists: true, datasource: datasourceId },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DatasourceAction.DeleteDatasource,
                    payload: { exists: false, datasource: null },
                });
                reject(err);
            });
    });
};

export const setRedirectDatasource = (dispatch, datasourceType) => {
    dispatch({
        type: DatasourceAction.SetRedirectDatasource,
        payload: {
            name: "",
            type: datasourceType,
            isDefault: false,
        },
    });
};
