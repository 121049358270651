import { setRedirectUrl } from "@common/redux/actions/authActions";
import { normalizeQueryParam, storeRedirectUrl } from "@common/utils/common-utils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const useRedirectUrl = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const redirectUrl = normalizeQueryParam(urlParams.get("redirectUrl"));

        if (redirectUrl) {
            storeRedirectUrl(redirectUrl);
            setRedirectUrl({ redirectUrl }, dispatch); // Dispatch Redux action to save the redirectUrl
        }
    }, [dispatch]);
};

export default useRedirectUrl;
