export const PlaygroundAction = {
    UpdateConfig: "UPDATE_CONFIG",
    UpdateOriginalUrl: "UPDATE_ORIGINAL_URL",
    UpdateTransformedUrl: "UPDATE_TRANSFORMED_URL",
    UpdatePreviewUrl: "UPDATE_PREVIEW_URL",
    UpdateTransformationList: "UPDATE_TRANSFORMATION_LIST",
    UpdateTransformationValues: "UPDATE_TRANSFORMATION_VALUES",
    UpdateDefaultTransformationValues: "UPDATE_DEFAULT_TRANFORMATION_VALUE",
    UpdateEditDefaultTransformationValues: "UPDATE_EDIT_DEFAULT_TRANSFORMATION_VALUES",
    AddTransformation: "ADD_TRANSFORMATION",
    AddPreset: "ADD_PRESET",
    ReplaceSelectedTransformation: "REPLACE_SELECTED_TRANSFORMATION",
    DeleteTransformationAtIdx: "DELETE_TRANSFORMATION_AT_IDX",
    UpdateSelectedTransformIdx: "UPDATE_SELECTED_TRANSFORM_IDX",
    StashTransformationList: "STASH_TRANSFORMATION_LIST",
    UnStashTransformationList: "UN_STASH_TRANSFORMATION_LIST",
    UnStashTrnsfrmtnListWithoutPreset: "UN_STASH_TRANSFORMATION_LIST_WITHOUT_PRESET",
    UpdatePresetData: "UPDATE_PRESET_DATA",
    UpdateFromUrl: "UPDATE_FROM_URL",
    ClearState: "CLEAR_STATE",
    AddDefaultTransformation: "ADD_DEFAULT_TRANFORMATION",
    EditDefaultTransformation: "EDIT_DEFAULT_TRANSFORMATION",
    UpdateDefaultTransformation: "UPDATE_DEFAULT_TRANFORMATION",
    UpdateEditDefaultTransformation: "UPDATE_EDIT_DEFAULT_TRANSFORMATION",
    ShowTransformation: "SHOW_TRANSFORMATION",
    ShowPreset: "SHOW_PRESET",
    ChangePlaygroundTab: "CHANGE_PLAYGROUND_TAB",
    OriginalImageDetails: "ORIGINAL_IMAGE_DETAILS",
    PreviewImageDetails: "PREVIEW_IMAGE_DETAILS",
    OriginalStatus: "ORIGINAL_STATUS",
    TransformedStatus: "TRANSFORMED_STATUS",
    JsonStatus: "JSON_STATUS",
    SetAppliedAndPreviewedTransformationList: "SET_APPLIED_AND_PREVIEWED_TRANSFORMATION_LIST",
};

export const updateConfig = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UpdateConfig,
        payload: data,
    });
};

export const updateOriginalUrl = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UpdateOriginalUrl,
        payload: data,
    });
};

export const updateTransformedUrl = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UpdateTransformedUrl,
        payload: data,
    });
};

export const updatePreviewUrl = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UpdatePreviewUrl,
        payload: data,
    });
};

export const updateTransformationList = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UpdateTransformationList,
        payload: data,
    });
};

export const updateTransformationValues = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UpdateTransformationValues,
        payload: data,
    });
};
export const updateDefaultTransformationValues = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UpdateDefaultTransformationValues,
        payload: data,
    });
};

export const updateEditDefaultTransformationValues = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UpdateEditDefaultTransformationValues,
        payload: data,
    });
};

export const addTransformation = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.AddTransformation,
        payload: data,
    });
};
export const addDefaultTransformation = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.AddDefaultTransformation,
        payload: data,
    });
};

export const editDefaultTransformation = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.EditDefaultTransformation,
        payload: data,
    });
};

export const updateDefaultTransformation = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UpdateDefaultTransformation,
        payload: data,
    });
};
export const updateEditDefaultTransformation = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UpdateEditDefaultTransformation,
        payload: data,
    });
};

export const addPreset = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.AddPreset,
        payload: data,
    });
};

export const replaceSelectedTransformation = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.ReplaceSelectedTransformation,
        payload: data,
    });
};

export const deleteTransformationAtIdx = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.DeleteTransformationAtIdx,
        payload: data,
    });
};

export const updateSelectedTransformIdx = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UpdateSelectedTransformIdx,
        payload: data,
    });
};

export const stashTransformationList = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.StashTransformationList,
        payload: data,
    });
};

export const unStashTransformationList = (dispatch) => {
    dispatch({
        type: PlaygroundAction.UnStashTransformationList,
        payload: undefined,
    });
};

export const unStashTrnsfrmtnListWithoutPreset = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UnStashTrnsfrmtnListWithoutPreset,
        payload: data,
    });
};

export const updatePresetData = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UpdatePresetData,
        payload: data,
    });
};

export const updateFromUrl = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.UpdateFromUrl,
        payload: data,
    });
};

export const clearState = (dispatch) => {
    dispatch({
        type: PlaygroundAction.ClearState,
        payload: undefined,
    });
};

export const showPreset = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.ShowPreset,
        payload: data,
    });
};
export const showTransformation = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.ShowTransformation,
        payload: data,
    });
};
export const changePlaygroundTab = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.ChangePlaygroundTab,
        payload: data,
    });
};
export const setOriginalImageDetails = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.OriginalImageDetails,
        payload: data,
    });
};
export const setPreviewImageDetails = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.PreviewImageDetails,
        payload: data,
    });
};
export const setOriginalStatus = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.OriginalStatus,
        payload: data,
    });
};
export const setTransformedStatus = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.TransformedStatus,
        payload: data,
    });
};
export const setJsonStatus = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.JsonStatus,
        payload: data,
    });
};

export const setAppliedAndPreviewedTransformationList = (data, dispatch) => {
    dispatch({
        type: PlaygroundAction.SetAppliedAndPreviewedTransformationList,
        payload: data,
    });
};
