export const selectionAction = {
    FetchAllItems: "FETCH_ALL_ITEMS",
    ShiftLeft: "SHIFT_LEFT",
    ShiftUp: "SHIFT_UP",
    ShiftRight: "SHIFT_RIGHT",
    ShiftDown: "SHIFT_DOWN",
    FetchAllManualSelection: "FETCH_ALL_MANUAL_SELECTION",
    ClearAllSelected: "CLEAR_ALL_SELECTED",
};

export const fetchAllItems = (data, dispatch) => {
    dispatch({
        type: selectionAction.FetchAllItems,
        payload: data,
    });
};

export const shiftLeft = (dispatch) => {
    dispatch({
        type: selectionAction.ShiftLeft,
    });
};

export const shiftUp = (dispatch) => {
    dispatch({
        type: selectionAction.ShiftUp,
    });
};

export const shiftRight = (dispatch) => {
    dispatch({
        type: selectionAction.ShiftRight,
    });
};

export const shiftDown = (dispatch) => {
    dispatch({
        type: selectionAction.ShiftDown,
    });
};

export const fetchAllManualSelection = (data, dispatch) => {
    dispatch({
        type: selectionAction.FetchAllManualSelection,
        payload: data,
    });
};

export const clearAllSelected = (dispatch) => {
    dispatch({
        type: selectionAction.ClearAllSelected,
    });
};
