import React from "react";
import BackIcon from "common/assets/BackIcon";
import IconButton from "./IconButton";

const BackButton = (props) => {
    return (
        <IconButton mode="filled" {...props}>
            <BackIcon height="30px" width="30px" />
        </IconButton>
    );
};

export default BackButton;
