import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import LoaderIcon from "common/components/LoaderIcon";
import PaymentsService from "services/payments.service";
import { useQuery } from "@common/utils/common-utils";
import { toast } from "react-toastify";

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

/**
 * Paddle creates a checkout URL, that does not have the `orgID`.
 * For such URLs, we open this page.
 *
 * The page extract transaction ID from URL. Hits our backend to get the transaction details.
 * The transaction details has orgID. We use that to open the actual checkout with orgID.
 */
const OpenCheckout = () => {
    const query = useQuery();
    const history = useHistory();
    const transactionId = query.get("_ptxn");

    useEffect(async () => {
        try {
            const response = await PaymentsService.getInvoiceWithoutOrg(transactionId);
            const orgId = response.data.metadata.orgId;

            history.push(
                `/organization/${orgId}/settings/billing/checkout?utmSource=pixelbin&_ptxn=${transactionId}`,
            );
        } catch (err) {
            toast.error("Something went wrong. Please try again.");
            history.push("/choose-org");
        }
    }, []);

    return (
        <LoadingContainer>
            <LoaderIcon />
        </LoadingContainer>
    );
};

export default OpenCheckout;
