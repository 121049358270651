import OrgService from "@src/services/org.service";

export const DomainAction = {
    DomainCreateSuccess: "CREATE_DOMAIN_SUCCESS",
    DomainCreateFailure: "CREATE_DOMAIN_FAILURE",
    GetDomain: "GET_DOMAIN",
    GetDomains: "GET_DOMAINS",
};

export const getDomains = (orgId, searchQuery, page, pageSize, dispatch) => {
    return new Promise((resolve, reject) => {
        OrgService.getDomains(orgId, searchQuery, page, pageSize)
            .then((res) => {
                const items = {};
                res.data.items.forEach((domain) => {
                    items[domain._id] = domain;
                });
                dispatch({
                    type: DomainAction.GetDomains,
                    payload: { domains: items, pages: res.data.pages },
                });
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DomainAction.GetDomains,
                    payload: { domain: null },
                });
                reject(err);
            });
    });
};

export const getDomainDetails = (orgId, domainId, dispatch) => {
    return new Promise((resolve, reject) => {
        OrgService.getDomainDetails(orgId, domainId)
            .then((res) => {
                dispatch({
                    type: DomainAction.GetDomain,
                    payload: { domain: res.data },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DomainAction.DomainCreateFailure,
                    payload: { domain: false },
                });
                reject(err);
            });
    });
};

export const createDomain = (orgId, domainData, dispatch) => {
    // const { name, hostname, provider } = domainData;
    return new Promise((resolve, reject) => {
        OrgService.createDomain(orgId, domainData)
            .then((res) => {
                dispatch({
                    type: DomainAction.DomainCreateSuccess,
                    payload: { domain: res.data },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DomainAction.DomainCreateFailure,
                    payload: { domain: false },
                });
                reject(err);
            });
    });
};

export const updateDomain = (orgId, domainId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        OrgService.updateDomain(orgId, domainId, data)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const deleteDomain = (orgId, domainId, dispatch) => {
    return new Promise((resolve, reject) => {
        OrgService.deleteDomain(orgId, domainId)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};
