import React, { useState, useEffect, createContext, useContext } from "react";

import { queries } from "../constants/breakpoints";

const defaultValue = {};

const BreakpointContext = createContext(defaultValue);

const BreakpointProvider = ({ children }) => {
    const [queryMatch, setQueryMatch] = useState({});

    useEffect(() => {
        const mediaQueryLists = {};
        const keys = Object.keys(queries);
        let isAttached = false;

        const handleQueryListener = () => {
            const updatedMatches = keys.reduce((acc, media) => {
                acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
                return acc;
            }, {});

            setQueryMatch(updatedMatches); // for change
        };

        if (window && window.matchMedia) {
            const matches = {};

            keys.forEach((media) => {
                mediaQueryLists[media] = window.matchMedia(queries[media]);
                matches[media] = mediaQueryLists[media].matches;
            });

            setQueryMatch(matches); // for first render

            isAttached = true;

            keys.forEach((media) => {
                mediaQueryLists[media].addEventListener("change", handleQueryListener);
            });
        }

        return () => {
            if (isAttached) {
                keys.forEach((media) => {
                    mediaQueryLists[media].removeEventListener("change", handleQueryListener);
                });
            }
        };
    }, [queries]);

    return <BreakpointContext.Provider value={queryMatch}>{children}</BreakpointContext.Provider>;
};

function useBreakpoint() {
    return useContext(BreakpointContext);
}

export { useBreakpoint, BreakpointProvider };
