import ExplorerService from "services/explorer.service";

export const DirectoryAction = {
    InitializeDirData: "INITIALIZE_DIR_DATA",
    SetDirChildren: "SET_DIR_CHILDREN",
    UpdateAddDeleteRefetch: "UPDATE_ADD_DELETE_REFETCH",
    ToggleIsOpen: "TOGGLE_IS_OPEN",
    SetActiveFolderId: "SET_ACTIVE_FOLDER_ID",
    SetActiveFolderName: "SET_ACTIVE_FOLDER_NAME",
    SetInitialState: "SET_INITIAL_STATE",
};

export const getInitialDirData = (dispatch, orgId, pageNo, pageSize) => {
    return new Promise((resolve, reject) => {
        ExplorerService.getItems(orgId, null, false, true, false, "name", true, pageNo, pageSize)
            .then((res) => {
                dispatch({
                    type: DirectoryAction.InitializeDirData,
                    payload: { directoryList: res.data.items, page: res.data.page },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DirectoryAction.InitializeDirData,
                    payload: { directoryList: [] },
                });
                reject(err);
            });
    });
};

export const getDirChildren = (dispatch, orgId, folderId, pageNo, pageSize, isShowMore = false) => {
    return new Promise((resolve, reject) => {
        ExplorerService.getItems(
            orgId,
            folderId,
            false,
            true,
            false,
            "name",
            true,
            pageNo,
            pageSize,
        )
            .then((res) => {
                dispatch({
                    type: DirectoryAction.SetDirChildren,
                    payload: {
                        _id: folderId,
                        directoryList: res.data.items,
                        page: res.data.page,
                        isShowMore,
                    },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DirectoryAction.SetDirChildren,
                    payload: { _id: folderId, directoryList: [] },
                });
                reject(err);
            });
    });
};

export const setDirChildren = (dispatch, payload) => {
    dispatch({
        type: DirectoryAction.SetDirChildren,
        payload,
    });
};

export const updateAddDeleteRefetch = (dispatch, addDeleteRefetch) => {
    dispatch({
        type: DirectoryAction.UpdateAddDeleteRefetch,
        payload: { addDeleteRefetch },
    });
};

export const toggleIsOpen = (dispatch, _id) => {
    dispatch({
        type: DirectoryAction.ToggleIsOpen,
        payload: { _id },
    });
};

export const setActiveFolderId = (dispatch, _id) => {
    dispatch({
        type: DirectoryAction.SetActiveFolderId,
        payload: { _id },
    });
};

export const setActiveFolderName = (dispatch, name) => {
    dispatch({
        type: DirectoryAction.SetActiveFolderName,
        payload: { name },
    });
};

export const setInitialState = (dispatch) => {
    dispatch({
        type: DirectoryAction.SetInitialState,
        payload: null,
    });
};
