import { searchDFS } from "../../../common/components/FolderTree/utils";
import { DirectoryAction } from "../actions/directoryActions";
import _cloneDeep from "lodash.clonedeep";

const initialState = {
    addDeleteRefetch: false,
    activeFolderId: null,
    directoryList: [
        {
            createdAt: "2021-12-20T14:36:18.819Z",
            height: null,
            metadata: null,
            name: "My Library",
            path: "",
            s3Key: null,
            tags: null,
            type: "folder",
            updatedAt: "2021-12-20T14:36:18.819Z",
            width: null,
            _id: "_rootId",
            files: [],
        },
    ],
};

const directoryReducer = (state = initialState, action) => {
    const { type, payload } = action;
    let newState = _cloneDeep(state);
    let node = null;
    let parent = null;
    if (action.payload && action.payload._id) {
        let foundNode = searchDFS({
            data: newState.directoryList,
            cond: (item) => {
                return item._id === action.payload._id;
            },
        });
        if (foundNode.item) {
            node = foundNode.item;
            parent = node.parentNode;
        }
    }
    switch (type) {
        case DirectoryAction.InitializeDirData:
            newState.directoryList[0].files = payload.directoryList;
            return {
                ...state,
                ...newState,
            };
        case DirectoryAction.SetDirChildren:
            if (node) {
                node.page = {};
                node.page = payload.page;
                if (node.files && node.files.length >= 0 && payload.isShowMore)
                    node.files = [...node.files, ...payload.directoryList];
                else {
                    node.files = [];
                    node.files = payload.directoryList;
                }
                return newState;
            } else return { ...state };
        case DirectoryAction.ToggleIsOpen:
            if (node) {
                node.isOpen = !node.isOpen;
                return newState;
            } else return { ...state };
        case DirectoryAction.UpdateAddDeleteRefetch:
            return {
                ...state,
                ...payload,
            };
        case DirectoryAction.SetActiveFolderId:
            return {
                ...state,
                activeFolderId: payload._id !== "_rootId" ? payload._id : null,
            };
        case DirectoryAction.SetInitialState:
            return initialState;

        default:
            return state;
    }
};

export default directoryReducer;
