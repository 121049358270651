import React from "react";
import { createGlobalStyle, css } from "styled-components";
import CloseIcon from "@playground/assets/svgs/playground/CrossToast.svg";
import SuccessIcon from "@playground/assets/svgs/playground/Tick Alt.svg";
import ErrorIcon from "@playground/assets/svgs/playground/Close Alt.svg";
import InfoIcon from "@playground/assets/svgs/playground/Info Alt.svg";
import WarnIcon from "@playground/assets/svgs/playground/Warn Alt.svg";
import GlobalStyle from "@src/GlobalStyle";

export const Breakpoints = {
    mobileXS: 480,
    mobile: 768,
    tablet: 1024,
    laptop: 1200,
    desktop: 1440,
    // custom break points
    topNavBar: 1225,
};

const ToastCss = css`
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.3);
    @media (min-width: ${Breakpoints.mobile}px) {
        border-radius: 8px !important;
    }
`;

const ToastIconCss = css`
    position: absolute;
    left: 12px;
    top: 6px;
    position: absolute;
    left: 14px;
    top: 0;
    height: calc(100% + 3px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PlaygroundStyle = createGlobalStyle`

input {
        border: 1px solid transparent;
        outline: none;
        letter-spacing: var(--letter-spacing);
    }

        /* Remove Remove Arrows/Spinners */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    input[type="checkbox"]{
        width: 20px;
        height: 20px;
        accent-color: var(--primary-light);
    }
    input[type="radio"]{
        width: 20px;
        height: 20px;
        accent-color: var(--primary-light);
    }
    input[type="range"] {
        -webkit-appearance: none;
    }

    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin-top: -5px;
        background: var(--primary-light);
        cursor: pointer;
        transition: background .3s ease-in-out;
    }

    input[type=range]::-webkit-slider-runnable-track  {
        -webkit-appearance: none;
        height: 4px;
        border-radius: 2px;
        border:1px solid var(--primary-light);
        background: transparent;
    }
    /* firefox */
        input[type=range]::-moz-range-thumb {
        border: none;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: var(--primary-light);
    }
    input[type=range]::-moz-range-track {
        height: 2px;
        background: transparent;
        border-radius: 2px;
        border:1px solid var(--primary-light);
    }

    /* ************************* */
    /* ************************* */
    /* Custom Toast design start */
    /* ************************* */
    /* ************************* */

    .Toastify__toast--success::after, .Toastify__toast--error::after, .Toastify__toast--info::after, .Toastify__toast--warning::after {
        content : url(${CloseIcon});// Your svg Path
        position: absolute;
        right: 14px;
        top: 0;
        height: calc(100% + 3px);
        color: var(--light-100);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Toastify__toast--success {
        ${ToastCss}
        background: #1A5637 !important;
    }
    .Toastify__toast--success::before {
        ${ToastIconCss}
        content: url(${SuccessIcon});
    }
    /* Info Toast Styles */
    .Toastify__toast--info {
        ${ToastCss}
        background: var(--primary-light) !important;
    }
    .Toastify__toast--info::before {
        ${ToastIconCss}
        content: url(${InfoIcon});
    }
    /* Error Toast */
    .Toastify__toast--error {
        ${ToastCss}
        background: #7C3034 !important;
    }
    .Toastify__toast--error::before {
        ${ToastIconCss}
        content: url(${ErrorIcon});
    }
    /* Warn Toast */
    .Toastify__toast--warning {
        ${ToastCss}
        background: linear-gradient(0deg, rgba(223, 227, 31, 0.4), rgba(223, 227, 31, 0.4)), #171921 !important;
    }
    .Toastify__toast--warning::before {
        ${ToastIconCss}
        content: url(${WarnIcon});
    }
    .Toastify__toast-body {
        color: var(--white);
        font-size: var(--body-2-d);
        padding: 10px 40px;
        font-weight: var(--semi-bold);
    }
    .Toastify__toast > button>  svg {
        display: none;
    }

    /* ************************ */
    /* ************************ */
    /* Custom Toast design ends */
    /* ************************ */
    /* ************************ */

`;

const PlaygroundStyleComp = () => {
    return (
        <>
            <GlobalStyle />
            <PlaygroundStyle />
        </>
    );
};

export default PlaygroundStyleComp;
