import React from "react";
import styled from "styled-components";

const Wrapper = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
    list-style: none;
    height: inherit;
    margin: 28px auto;
    width: 100%;
    max-width: 1200px;
    flex-grow: 1;
    gap: 16px;
`;

const ListLayout = ({ children, ...props }) => <Wrapper {...props}>{children}</Wrapper>;

export default ListLayout;
