import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Breakpoints } from "../../../GlobalStyle";
import { useSelector } from "react-redux";

const StyledImage = styled.img`
    height: ${({ height }) => (height ? height : "28px")};
    width: ${({ width }) => (width ? width : "auto")};
    cursor: pointer;
    @media (max-width: ${Breakpoints.mobile}px) {
        height: 24px;
    }
`;
const ResponsiveLogo = ({ width, height, isRedirect, isReponsive = true }) => {
    const isMobile = useSelector((state) => state.userAgentDetails.isMobile);

    let url =
        isMobile && isReponsive
            ? "https://cdn.pixelbin.io/v2/dummy-cloudname/original/__logos__/pixelbin/pixelbin_favicon.png"
            : "https://cdn.pixelbin.io/v2/dummy-cloudname/original/__logos__/pixelbin/pixelbin_logo_light.png";

    return (
        <>
            {isRedirect ? (
                <Link to="/">
                    <StyledImage width={width} height={height} alt={"logo"} src={url} />
                </Link>
            ) : (
                <StyledImage width={width} height={height} alt={"logo"} src={url} />
            )}
        </>
    );
};

export default ResponsiveLogo;
