import { DatasourceAction } from "../actions/datasourceAction";

const initialState = {
    datasourceList: {
        datasources: [],
        page: {},
    },
    datasource: {},
    initValue: {},
};

const datasourceReducer = (state = initialState, action) => {
    const { type, payload } = action;
    const newState = { ...state };
    let datasource;

    switch (type) {
        case DatasourceAction.GetDatasources:
            // do not overrider zones list, instead concatenate them
            const prevDatasources = state.datasourceList.datasources;
            const nextDatasources = payload.datasourceList.datasources;
            const updatedState = { ...state, ...payload };
            updatedState.datasourceList.datasources = [...prevDatasources, ...nextDatasources];
            return updatedState;
        case DatasourceAction.DatasourceCreateSuccess:
            datasource = payload.datasource;
            newState.datasourceList.datasources[datasource._id] = datasource;
            return {
                ...state,
                ...newState,
            };
        case DatasourceAction.DatasourceCreateFailed:
            return {
                ...state,
            };
        case DatasourceAction.UpdateDatasource:
            if (payload.isDatasourceUpdated) {
                datasource = payload.datasource;
                newState.datasourceList.datasources[datasource._id] = datasource;
                return {
                    ...state,
                    ...newState,
                };
            }
            return state;
        case DatasourceAction.GetDatasource:
            if (payload.exists) {
                datasource = payload.datasource;
                newState.datasourceList.datasources[datasource._id] = datasource;
                return {
                    ...state,
                    ...newState,
                };
            }
            return state;
        case DatasourceAction.DeleteDatasource:
            datasource = payload.datasource;
            newState.datasourceList.datasources = newState.datasourceList.datasources.filter(
                (_) => _._id !== datasource,
            );
            return {
                ...state,
                ...newState,
            };

        case DatasourceAction.SetRedirectDatasource:
            return {
                ...state,
                initValue: payload,
            };

        default:
            return state;
    }
};

export default datasourceReducer;
