export const UserAgentAction = {
    AddAgent: "ADD_AGENT",
    SetFcWidgetLoaded: "SET_FC_WIDGET_LOADED",
    SetNewUser: "SET_NEW_USER",
    SetReportIssueClicked: "SET_REPORT_ISSUE_CLICKED",
};

export const addUserAgent = (data, dispatch) => {
    dispatch({
        type: UserAgentAction.AddAgent,
        payload: data,
    });
};

export const setWidgetLoaded = (data, dispatch) => {
    dispatch({
        type: UserAgentAction.SetFcWidgetLoaded,
        payload: data,
    });
};

export const setNewUser = (data, dispatch) => {
    dispatch({
        type: UserAgentAction.SetNewUser,
        payload: data,
    });
};
export const setReportIssue = (data, dispatch) => {
    dispatch({
        type: UserAgentAction.SetReportIssueClicked,
        payload: data,
    });
};
