import React from "react";
import NewCardForm from "./NewCardForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import environment from "../../../../../../environment";

const stripePromise = loadStripe(environment.APP_STRIPE_PUBLISHABLE_KEY);

export default function AddNewCard({ title, orgId, refetchCards, setDefault, useOldCard }) {
    return (
        <Elements stripe={stripePromise}>
            <NewCardForm
                title={title}
                orgId={orgId}
                refetchCards={refetchCards}
                setDefault={setDefault}
                useOldCard={useOldCard}
            />
        </Elements>
    );
}
