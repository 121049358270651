import "abortcontroller-polyfill";
// import { fetch as fetchPolyfill } from "whatwg-fetch";
// import fetchBuilder from "fetch-retry";
import environment from "../../environment";
// import { sign } from "../../services/interceptors/signature";
import ApiService from "../../services/api.service";

const fetch = (url, payload) => {
    let axiosOptions = {
        method: payload.method,
        url,
        data: payload.body,
    };

    if (payload.responseType) {
        axiosOptions["responseType"] = payload?.responseType;
    }

    return new Promise(async (resolve, reject) => {
        try {
            const res = await ApiService.axios(axiosOptions);
            if (res.status === 200) {
                resolve(await res);
            } else {
                reject(await res);
            }
        } catch (error) {
            reject(error);
        }
    });
};

// const fetch = fetchBuilder(fetchPolyfill);

let aborter = null;
const dataURLRegex =
    /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;
const resourceURlRegex = /(http(s?):)|([/|.|\w|\s])*\.(?:jpg|png|jfif|jpeg|bmp|heif|bat|webp|raw)/i;

const b64toBlob = (dataURI) => {
    return new Promise((resolve, reject) =>
        fetch(dataURI, { responseType: "blob" })
            .then((res) => res.data)
            .then((res) => resolve(res))
            .catch((e) => reject(e)),
    );
};

export const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};

function isDataURL(s) {
    return !!s.match(dataURLRegex);
}

function isResourceURl(s) {
    return !!s.match(resourceURlRegex);
}

// @deprecated
export const eraseBGFromImage = async (orgId, image_url) => {
    // const headers = {
    //   'Access-Control-Allow-Origin': '*',
    //   authorization: `Bearer ${hash}`
    // };

    // let api = `${environment.KAKASHI_MAIN_URL}/api/v1/core/predict_open`;
    // if (isUserLoggedIn()) {
    //   api = `${environment.KAKASHI_MAIN_URL}/api/v1/core/predict`;
    // }
    let api;
    if (orgId) {
        api = `${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/core/predict`;
    } else {
        api = `${environment.KAKASHI_MAIN_URL}/api/v1/core/predict_open`;
    }

    if (aborter) {
        aborter.abort();
    }

    aborter = new AbortController();
    const signal = aborter.signal;

    const fd = new FormData();

    return new Promise(async (resolve, reject) => {
        if (isDataURL(image_url)) {
            const blob = await b64toBlob(image_url);
            fd.append("image_file", blob);
            const image_url_b64 = image_url.replace(/^data:image\/[a-z]+;base64,/, "");
        } else {
            if (!isResourceURl(image_url)) {
                reject("Unsupported image format encountered");
            }
            fd.append("image_url", image_url);
        }

        fetch(api, {
            method: "POST",
            body: fd,
            signal,
            retries: 2,
            responseType: "blob",
            retryOn: function (attempt, error, response) {
                if (attempt === 2) return false;
                if (error !== null || !response || response.status >= 400) {
                    return true;
                }
                return false;
            },
        })
            .then(async (res) => {
                const xToken = res.headers["x-ebg-image-token"];
                if (xToken) {
                    sessionStorage.setItem("x-ebg-image-token", xToken);
                }
                aborter = null;
                if (res.status === 200) resolve(res);
                else reject(res);
            })
            .catch((e) => {
                if (e.name === "AbortError") console.error(e);
                else reject(e);
            });
    });
};
