export const ORG_ID = 1;
export const initialState = {
    authenticationDetails: {
        isUserLoggedIn: true,
        user: {
            firstName: "John",
            lastName: "Doe",
            email: "test@domain.co",
            userName: "test@domain.co",
        },
    },
    organizationDetails: {
        isOrgRegistered: true,
        organization: {
            _id: 18,
            name: "test_org",
            cloudName: "test_cloud_name",
            ownerId: "test_owner_id",
            active: true,
            createdAt: "2021-07-28T15:22:34.000Z",
            updatedAt: "2021-07-28T15:22:34.000Z",
        },
        member: {
            type: "owner",
            roles: {
                1: {
                    permissions: {
                        billing: {
                            read: true,
                            write: true,
                        },
                    },
                },
            },
        },
    },
};
export const PLANS = {
    basicMonthly: {
        _id: 1,
        createdAt: "2022-05-23T07:00:46.164Z",
        updatedAt: "2022-05-23T07:00:46.164Z",
        metadata: null,
        name: "basic_monthly",
        display: "Basic",
        priority: 1,
        description: null,
        interval: "monthly_test",
        amount: "40.00",
        custom: false,
        creditsOverageRate: null,
        storageOverageRate: null,
        features: {
            credits: {
                _id: 1,
                createdAt: "2022-05-23T06:58:17.537Z",
                updatedAt: "2022-05-24T16:27:56.256Z",
                metadata: null,
                name: "credits",
                display: "Credits",
                description: null,
                quantity: "200.00",
            },
            storage: {
                _id: 2,
                createdAt: "2022-05-23T06:58:28.014Z",
                updatedAt: "2022-05-24T16:27:56.256Z",
                metadata: null,
                name: "storage",
                display: "GB Storage",
                description: null,
                quantity: "200.00",
            },
        },
    },
    basicYearly: {
        _id: 3,
        createdAt: "2022-05-23T07:01:56.577Z",
        updatedAt: "2022-05-23T07:01:56.577Z",
        metadata: null,
        name: "basic_yearly",
        display: "Basic",
        priority: 1,
        description: null,
        interval: "yearly_test",
        amount: "35.00",
        custom: false,
        creditsOverageRate: null,
        storageOverageRate: null,
        features: {
            credits: {
                _id: 1,
                createdAt: "2022-05-23T06:58:17.537Z",
                updatedAt: "2022-05-24T16:27:56.256Z",
                metadata: null,
                name: "credits",
                display: "Credits",
                description: null,
                quantity: "200.00",
            },
            storage: {
                _id: 2,
                createdAt: "2022-05-23T06:58:28.014Z",
                updatedAt: "2022-05-24T16:27:56.256Z",
                metadata: null,
                name: "storage",
                display: "GB Storage",
                description: null,
                quantity: "200.00",
            },
        },
    },
    premiumMonthly: {
        _id: 2,
        createdAt: "2022-05-23T07:01:21.488Z",
        updatedAt: "2022-05-23T17:03:18.404Z",
        metadata: null,
        name: "premium_monthly",
        display: "Premium",
        priority: 2,
        description: null,
        interval: "monthly_test",
        amount: "140.00",
        custom: false,
        creditsOverageRate: null,
        storageOverageRate: null,
        features: {
            credits: {
                _id: 1,
                createdAt: "2022-05-23T06:58:17.537Z",
                updatedAt: "2022-05-24T16:27:56.256Z",
                metadata: null,
                name: "credits",
                display: "Credits",
                description: null,
                quantity: "800.00",
            },
            storage: {
                _id: 2,
                createdAt: "2022-05-23T06:58:28.014Z",
                updatedAt: "2022-05-24T16:27:56.256Z",
                metadata: null,
                name: "storage",
                display: "GB Storage",
                description: null,
                quantity: "800.00",
            },
        },
    },
    premiumYearly: {
        _id: 4,
        createdAt: "2022-05-23T07:02:26.376Z",
        updatedAt: "2022-05-23T17:03:26.615Z",
        metadata: null,
        name: "premium_yearly",
        display: "Premium",
        priority: 2,
        description: null,
        interval: "yearly_test",
        amount: "123.00",
        custom: false,
        creditsOverageRate: null,
        storageOverageRate: null,
        features: {
            credits: {
                _id: 1,
                createdAt: "2022-05-23T06:58:17.537Z",
                updatedAt: "2022-05-24T16:27:56.256Z",
                metadata: null,
                name: "credits",
                display: "Credits",
                description: null,
                quantity: "800.00",
            },
            storage: {
                _id: 2,
                createdAt: "2022-05-23T06:58:28.014Z",
                updatedAt: "2022-05-24T16:27:56.256Z",
                metadata: null,
                name: "storage",
                display: "GB Storage",
                description: null,
                quantity: "800.00",
            },
        },
    },
    freePlan: {
        _id: -99,
        createdAt: "2022-05-23T07:00:05.181Z",
        updatedAt: "2022-05-24T15:20:38.648Z",
        metadata: null,
        name: "free_plan",
        display: "Free",
        priority: -1,
        description: null,
        interval: "monthly_test",
        amount: "0.00",
        custom: false,
        creditsOverageRate: "0.40",
        storageOverageRate: "0.40",
        currencyCode: "USD",
        features: {
            credits: {
                _id: 1,
                createdAt: "2022-05-23T06:58:17.537Z",
                updatedAt: "2022-05-24T16:27:56.256Z",
                metadata: null,
                name: "credits",
                display: "Credits",
                description: null,
                quantity: "25.00",
            },
            storage: {
                _id: 2,
                createdAt: "2022-05-23T06:58:28.014Z",
                updatedAt: "2022-05-24T16:27:56.256Z",
                metadata: null,
                name: "storage",
                display: "GB Storage",
                description: null,
                quantity: "25.00",
            },
        },
    },
};

export const ADDONS = [
    {
        _id: 1,
        createdAt: "2023-05-06T18:22:46.559Z",
        updatedAt: "2023-05-06T18:22:46.559Z",
        metadata: null,
        name: "basic",
        display: "Basic",
        description: null,
        amount: "10.00",
        durationInDays: 30,
        custom: false,
        features: [
            {
                _id: 1,
                createdAt: "2023-05-05T06:47:47.184Z",
                updatedAt: "2023-05-05T06:47:47.184Z",
                metadata: null,
                name: "credits",
                display: "Credits",
                description: null,
                quantity: "45.00",
            },
        ],
        TRANSFORMATION_CREDITS_MAPPING: {
            WatermarkRemoval: 1,
            WatermarkDetection: 0.33,
            SuperResolution: 1,
            EraseBG: 1,
            BackgroundGenerator: 5,
        },
        CREDITS_PER_GB_BANDWIDTH: 3,
        DEFAULT_TRANSFORMATION_CREDITS: 0.0033333333333333335,
    },
    {
        _id: 3,
        createdAt: "2023-05-06T18:23:43.522Z",
        updatedAt: "2023-05-06T18:23:43.522Z",
        metadata: null,
        name: "starter",
        display: "Starter",
        description: null,
        amount: "50.00",
        durationInDays: 30,
        custom: false,
        features: [
            {
                _id: 1,
                createdAt: "2023-05-05T06:47:47.184Z",
                updatedAt: "2023-05-05T06:47:47.184Z",
                metadata: null,
                name: "credits",
                display: "Credits",
                description: null,
                quantity: "105.00",
            },
        ],
        TRANSFORMATION_CREDITS_MAPPING: {
            WatermarkRemoval: 1,
            WatermarkDetection: 0.33,
            SuperResolution: 1,
            EraseBG: 1,
            BackgroundGenerator: 5,
        },
        CREDITS_PER_GB_BANDWIDTH: 3,
        DEFAULT_TRANSFORMATION_CREDITS: 0.0033333333333333335,
    },
];

export const CARD_DETAILS = {
    _id: 123456,
    createdAt: "2023-11-21T07:20:28.568Z",
    updatedAt: "2023-12-07T07:11:27.083Z",
    metadata: {},
    customerId: 6008728,
    name: "Card Ownew Name",
    addressLine1: null,
    addressCity: null,
    addressPostalCode: null,
    addressState: null,
    addressCountry: null,
    default: false,
    last4: "0008",
    brand: "visa",
    expMonth: 1,
    expYear: 2030,
    cardCountry: "IN",
    stripePaymentMethodId: "pm_1OEoFhSIcn3d6hi2K0nGIN6W",
    currency: "inr",
};

export const CUSTOMOR_ADDRESS = {
    addressLine1: "addressLine1",
    addressCity: "addressCity",
    addressState: "addressState",
    addressCountry: "addressCountry",
    addressPostalCode: 122212,
    metadata: { type: "object" },
    createdAt: { type: "string", format: "date-time" },
    updatedAt: { type: "string", format: "date-time" },
};
export const ITEM_TYPE = {
    SUBSCRIPTION: "subscription",
    ADDON: "addon",
};
export const subscription = {
    _id: 24,
    createdAt: "2022-05-24T07:28:25.840Z",
    updatedAt: "2022-05-24T12:30:04.791Z",
    metadata: null,
    customerId: 5,
    planId: -99,
    active: true,
    startedAt: "2022-05-24T07:30:03.691Z",
    canceledAt: null,
    currentPeriodStart: "2022-05-24T12:30:04.753Z",
    currentPeriodEnd: "2022-05-24T12:40:00.000Z",
    cancelAtPeriodEnd: false,
    plan: PLANS.freePlan,
};
export const INVOICES = {
    items: [
        {
            _id: 13,
            createdAt: "2022-05-24T07:30:05.372Z",
            updatedAt: "2022-05-24T08:30:20.372Z",
            metadata: {
                customerId: "5",
                orgId: "267",
                subscriptionId: "23",
                planId: "1",
                team: "pixelbin",
                service: "rupika",
            },
            customerId: 5,
            stripeInvoiceId: "in_1L2sBsHY5NCLOJpYTcsTwRXq",
            hostedInvoiceUrl:
                "https://invoice.stripe.com/i/acct_1Gs65THY5NCLOJpY/test_YWNjdF8xR3M2NVRIWTVOQ0xPSnBZLF9Ma012QzhtaXRDUDU4bmVUYlBOWHRlckQxSTZMWlZOLDQzOTIxODE50200CFFdEzK7?s=ap",
            invoicePdf:
                "https://pay.stripe.com/invoice/acct_1Gs65THY5NCLOJpY/test_YWNjdF8xR3M2NVRIWTVOQ0xPSnBZLF9Ma012QzhtaXRDUDU4bmVUYlBOWHRlckQxSTZMWlZOLDQzOTIxODE50200CFFdEzK7/pdf?s=ap",
            status: "paid",
            total: 358720,
            currency: "inr",
            dueDate: null,
        },
        {
            _id: 12,
            createdAt: "2022-05-24T07:20:08.697Z",
            updatedAt: "2022-05-24T08:20:26.806Z",
            metadata: {
                customerId: "5",
                orgId: "267",
                subscriptionId: "23",
                planId: "1",
                team: "pixelbin",
                service: "rupika",
            },
            customerId: 5,
            stripeInvoiceId: "in_1L2s2FHY5NCLOJpYMqPdX35W",
            hostedInvoiceUrl:
                "https://invoice.stripe.com/i/acct_1Gs65THY5NCLOJpY/test_YWNjdF8xR3M2NVRIWTVOQ0xPSnBZLF9Ma01sQlJ3cEVyaUJTaTQxWkVud1dUd1lyRzEybEVULDQzOTIxMjI10200RER5XSUQ?s=ap",
            invoicePdf:
                "https://pay.stripe.com/invoice/acct_1Gs65THY5NCLOJpY/test_YWNjdF8xR3M2NVRIWTVOQ0xPSnBZLF9Ma01sQlJ3cEVyaUJTaTQxWkVud1dUd1lyRzEybEVULDQzOTIxMjI10200RER5XSUQ/pdf?s=ap",
            status: "open",
            total: 358720,
            currency: "inr",
            dueDate: null,
        },
    ],
    page: { type: "number", size: 2, current: 1, hasNext: false, itemTotal: 2 },
};
export const CARDS = {
    items: [
        {
            _id: 8,
            createdAt: "2022-05-26T08:18:13.650Z",
            updatedAt: "2022-05-26T08:32:28.200Z",
            metadata: null,
            customerId: 5,
            name: "Amey",
            addressLine1: "Ghatkopar",
            addressCity: "Mumbai",
            addressPostalCode: "400084",
            addressState: "Maharashtra",
            addressCountry: "IN",
            brand: "visa",
            expMonth: 12,
            expYear: 2024,
            cardCountry: "IN",
            stripePaymentMethodId: "pm_1L3btKHY5NCLOJpY6spS1aYx",
            default: false,
            last4: "0008",
        },
        {
            _id: 9,
            createdAt: "2022-05-26T08:30:13.815Z",
            updatedAt: "2022-05-26T08:32:28.202Z",
            metadata: null,
            customerId: 5,
            name: "Amey",
            addressLine1: "GH",
            addressCity: "Mumbai",
            addressPostalCode: "98140",
            addressState: "California",
            addressCountry: "US",
            brand: "visa",
            expMonth: 4,
            expYear: 2042,
            cardCountry: "US",
            stripePaymentMethodId: "pm_1L3c59HY5NCLOJpYhhVOGzP7",
            default: true,
            last4: "4242",
        },
    ],
    page: { type: "number", size: 2, current: 1, hasNext: false, itemTotal: 2 },
};
