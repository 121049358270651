import React from "react";
import styled from "styled-components";
import { Breakpoints } from "../../../GlobalStyle";

const Dot = styled.div`
    width: 7px;
    height: 7px;
    border-radius: 50%;
`;

const SuccessDot = styled(Dot)`
    background: var(--success);
    @media (max-width: ${Breakpoints.mobileXS}px) {
        display: none;
    }
`;

const FailedDot = styled(Dot)`
    background: var(--error);
    @media (max-width: ${Breakpoints.mobileXS}px) {
        display: none;
    }
`;

export const SUB_HEADING_TAB = [
    {
        id: 0,
        label: "All",
        countType: "totalCount",
        fileType: "uploadProgress",
    },
    {
        id: 1,
        label: "Uploaded",
        countType: "successCount",
        fileType: "successFiles",
        icon: <SuccessDot />,
    },
    {
        id: 2,
        label: "Failed",
        countType: "failedCount",
        fileType: "failedFiles",
        icon: <FailedDot />,
    },
];
