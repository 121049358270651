import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import retry from "async-retry";

import { Breakpoints } from "GlobalStyle";
import TextInput from "common/components/TextInput";
import PaymentService from "services/payments.service.js";
import { billingConstants } from "../../../constants";
import Button from "common/components/Button";
import BoxLoader from "common/components/BoxLoader";
import PaymentsService from "services/payments.service";
import {
    StyledError,
    StyledErrorSkeleton,
} from "../../../../../../common/global-styled-components";
import CheckboxInput from "../../../../../../common/components/CheckboxInput";
import Tooltip from "common/components/Tooltip";
import { EventEmitter } from "@common/events/eventEmitter";
import {
    CARD_ADDITION_FAILURE,
    CARD_ADDITION_SUCCESS,
    CARD_SETUP_SUCCESS,
} from "@common/events/events";
import { useSelector } from "react-redux";

const FormWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: ${({ title }) => (title ? "var(--dark-800)" : "")};
    border-radius: 8px;
`;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 25px 25px 25px;
    width: 100%;
    overflow-y: auto;

    @media (max-width: ${Breakpoints.mobile}px) {
        padding: 10px 0px;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => props.marginBottom};
    margin-top: ${(props) => props.marginTop};
`;

const Label = styled.label`
    font-size: var(--body-3-d);
    font-weight: var(--font-weight-400);
    color: var(--light-300);
    width: 100%;
    margin-bottom: 10px;
`;

const Heading = styled.div`
    font-size: var(--subtitle-2-d);
    font-weight: var(--font-weight-600);
    padding: 25px;
`;

const CardElementWrapper = styled.div`
    background: var(--dark-900);
    padding: 10px;
    border-radius: var(--border-radius-4);
    height: 40px;
    border: 1px solid var(--border-dark);
`;

const LabelText = styled.p`
    white-space: nowrap;
    padding-left: 10px;
    color: var(--light-300);
`;

const CheckBoxLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    width: 50px;
`;

const NewStyledError = styled(StyledError)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const NewCardForm = ({ title, orgId, refetchCards, setDefault, useOldCard }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [cardError, setCardError] = useState();
    const [defaultCard, setDefaultCard] = useState(setDefault ? setDefault : false);
    const { email } = useSelector((state) => state?.authenticationDetails?.user);
    const [customerAddress, setCustomerAddress] = useState(null);

    const fetchCustomerAddress = (orgId) => {
        PaymentsService.getCustomerAddress(orgId)
            .then((res) => {
                setCustomerAddress(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const requiredValidation = { required: true };

    const confirmCardSetupWithRetry = (...args) => {
        return retry(
            async (bail) => {
                try {
                    const result = await stripe.confirmCardSetup(...args);

                    // Retry-able error
                    if (result.error?.code === "lock_timeout") {
                        return Promise.reject(result.error);
                    }

                    return result;
                } catch (err) {
                    // Don't retry for any other errors
                    bail(err);
                }
            },
            {
                retries: 5,
                factor: 1.5,
                minTimeout: 500,
            },
        );
    };

    const onSubmit = async (data) => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setSubmitting(true);

        try {
            const { clientSecret } = (await PaymentService.initCard(orgId)).data;

            const eventPayload = {
                name: data.name,
            };

            const result = await confirmCardSetupWithRetry(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: data.name,
                        address: {
                            line1: customerAddress?.addressLine1,
                            postal_code: customerAddress?.addressPostalCode,
                            city: customerAddress?.addressCity,
                            state: customerAddress?.addressState,
                            country: customerAddress?.addressCountry,
                        },
                    },
                },
            });

            if (result.error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                setCardError(result.error.message);
                EventEmitter.dispatch(CARD_ADDITION_FAILURE, {
                    ...eventPayload,
                    errorMessage: result.error.message,
                });
            } else {
                EventEmitter.dispatch(CARD_ADDITION_SUCCESS, eventPayload);

                const { payment_method } = result.setupIntent;
                const cardResult = await PaymentService.saveCard(orgId, {
                    paymentMethodId: payment_method,
                    setAsDefault: data.default === "true",
                });
                EventEmitter.dispatch(CARD_SETUP_SUCCESS, { email });
                toast.success("New card created");
                refetchCards();
            }
        } catch (err) {
            const errorEventPayload = {
                name: data.name,
            };

            EventEmitter.dispatch(CARD_ADDITION_FAILURE, {
                ...errorEventPayload,
                error: err,
            });
            console.log(err?.response?.data?.message);
            toast.error(
                err?.response?.data?.message ||
                    "We're unable to process your request at the moment",

                { autoClose: 2500 },
            );
        }

        setSubmitting(false);
    };

    useEffect(() => {
        fetchCustomerAddress(orgId);
    }, []);

    return (
        <BoxLoader
            stretch={true}
            opacity={"10"}
            zIndex={99}
            loading={paymentsLoading || submitting}
        >
            <form onSubmit={handleSubmit(onSubmit)} data-testid="add-card-form">
                <FormWrapper {...{ title }}>
                    {title && <Heading>{title}</Heading>}
                    <DetailsWrapper>
                        <InputWrapper marginBottom="10px">
                            <TextInput
                                data-testid="name"
                                name="name"
                                label="name"
                                id="name"
                                inputLabel="Name"
                                required={true}
                                validation={{
                                    required: true,
                                    pattern: /^[a-zA-Z0-9_-]+(?:\s[a-zA-Z0-9_-]+)*$/,
                                }}
                                register={register}
                                type="text"
                                placeholder="Name"
                                paddingRight="40px"
                                className="pii-data"
                                // width="200px"
                            ></TextInput>
                            <StyledErrorSkeleton>
                                <StyledError role="error">
                                    {errors.name?.type === "required"
                                        ? "Name is required"
                                        : errors.name?.type === "pattern"
                                        ? "Name should be alphanumeric, hyphen or underscore"
                                        : ""}
                                </StyledError>
                                {/* {errors.name && errors.name?.type === "required" && (
                                    <StyledError role="error">Name is required</StyledError>
                                )}
                                {errors.name && errors.name?.type === "pattern" && (
                                    <StyledError role="error">Name is required</StyledError>
                                )} */}
                                {!errors.name && <StyledError></StyledError>}
                            </StyledErrorSkeleton>
                        </InputWrapper>

                        <InputWrapper marginBottom="10px">
                            <Label>Card Details *</Label>
                            <CardElementWrapper>
                                <CardElement
                                    options={{
                                        hidePostalCode: true,
                                        style: billingConstants.cardElementStyle,
                                    }}
                                    onReady={(e) => {
                                        setPaymentsLoading(false);
                                    }}
                                    onChange={() => setCardError(null)}
                                    className="pii-data"
                                />
                            </CardElementWrapper>
                            <StyledErrorSkeleton>
                                {cardError && (
                                    <>
                                        <NewStyledError
                                            data-for="stripe-error"
                                            data-tip
                                            role="tooltip"
                                        >
                                            {cardError}
                                        </NewStyledError>
                                        <Tooltip
                                            id="stripe-error"
                                            effect="solid"
                                            delayShow={500}
                                            type="error"
                                        >
                                            {cardError}
                                        </Tooltip>
                                    </>
                                )}
                                {!errors.state && <StyledError></StyledError>}
                            </StyledErrorSkeleton>
                        </InputWrapper>

                        <InputWrapper marginBottom="10px">
                            <CheckBoxLabel>
                                <CheckboxInput
                                    register={register}
                                    name="default"
                                    value={defaultCard}
                                    checked={defaultCard}
                                    onChange={() => setDefaultCard(!defaultCard)}
                                    label="default"
                                    data-testid="read-checkbox"
                                    width="20px"
                                />
                                <LabelText>Set as default</LabelText>
                            </CheckBoxLabel>
                        </InputWrapper>
                        <Button width="100%" onClick={handleSubmit} role="add-card">
                            Add Card
                        </Button>
                    </DetailsWrapper>
                </FormWrapper>
            </form>
        </BoxLoader>
    );
};

export default NewCardForm;
