import { WebsiteConfigAction } from "../actions/websiteConfigActions";

const initialState = {
    socialMediaLinks: [],
    lastUpdated: 0,
};

const websiteConfigReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case WebsiteConfigAction.SET_WEBSITECONFIG:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default websiteConfigReducer;
