import React, { createContext, useContext, useState } from "react";

const CropContext = createContext(null);

const CropContextProvider = ({ children }) => {
    const initialCropValue = "";
    const initialCropStatus = {
        isMulti: false,
        isCropDisabled: true,
        onChange: () => {}, // call to update input
    };

    const [showCropPortal, setShowCropPortal] = useState(false);
    const [cropStatus, setCropStatus] = useState(initialCropStatus);
    const [originalCropValue, setOriginalCropValue] = useState(initialCropValue); // used to check if value changed from original
    const [previewTab, setPreviewTab] = useState("original");
    const [showBulkTransformPanel, setShowBulkTransformPanel] = useState(false);

    const resetCropValue = () => setOriginalCropValue(initialCropValue);
    const resetCropStatus = () => setCropStatus(initialCropStatus);

    const closeCropPortal = () => {
        resetCropValue();
        resetCropStatus();
        setShowCropPortal(false);
    };

    return (
        <CropContext.Provider
            value={{
                originalCropValue,
                cropStatus,
                showCropPortal,
                previewTab,
                showBulkTransformPanel,
                setOriginalCropValue,
                resetCropValue,
                resetCropStatus,
                setCropStatus,
                setShowCropPortal,
                setPreviewTab,
                setShowBulkTransformPanel,
                closeCropPortal,
            }}
        >
            {children}
        </CropContext.Provider>
    );
};

const useCropContext = () => useContext(CropContext);

export { CropContextProvider, useCropContext };
