import URLS from "common/utils/apiUrls";
import ApiService from "./api.service";

const UserService = {
    verfiySessionToken(tokenID) {
        const axiosOptions = {
            params: { tokenID },
        };
        return ApiService.post(URLS.setCookieToHinata, axiosOptions);
    },

    getCurrentUserSession() {
        let axiosOptions = {};
        return ApiService.get(URLS.currentUserSession, axiosOptions);
    },

    createOrganization(data) {
        let axiosOptions = Object.assign(
            {},
            {
                data,
            },
        );
        return ApiService.post(URLS.createOrganization, axiosOptions);
    },

    getOrganizationDetails() {
        let axiosOptions = {};
        return ApiService.get(URLS.getOrganizationDetails, axiosOptions);
    },

    getOrganizationsForUser() {
        let axiosOptions = {};
        return ApiService.get(URLS.getOrganizationsForUser, axiosOptions);
    },

    getOrganizationDetailsById(orgId) {
        let axiosOptions = {};
        return ApiService.get(
            URLS.getOrganizationDetailsById.replace(":orgId", orgId),
            axiosOptions,
        );
    },

    deleteAccount() {
        let axiosOptions = {};
        return ApiService.delete(URLS.deleteAccount, axiosOptions);
    },
};

export default UserService;
