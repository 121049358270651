import { useCallback, useEffect } from "react";

const KEY_NAME_ESC = "Escape";
const KEY_EVENT_TYPE = "keyup";

export const useEscapeKey = (handleClose, dependencyArray = []) => {
    const isActionPossible = dependencyArray.reduce((res, curr) => res && curr, true);

    const handleEscKey = useCallback(
        (event) => {
            if (event.key === KEY_NAME_ESC && isActionPossible) {
                handleClose();
            }
        },
        [handleClose, isActionPossible],
    );

    useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, true);

        return () => {
            document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, true);
        };
    }, [handleEscKey]);
};
