// useLoadGoogleOneTapScript.js
import { useEffect } from "react";
import EventEmitter from "../events";
import {
    GOOGLE_ONE_TAP_DISMISSED,
    GOOGLE_ONE_TAP_NOT_DISPLAYED,
    GOOGLE_ONE_TAP_SHOWN,
    GOOGLE_ONE_TAP_SKIPPED,
} from "../events/events";

const useGoogleOneTapImpressions = () => {
    const continueWithNextIdp = (notification) => {
        if (!notification) {
            throw new Error("Invalid notification object from Google One Tap.");
        }
        if (notification.isNotDisplayed()) {
            EventEmitter.dispatch(GOOGLE_ONE_TAP_NOT_DISPLAYED);
        } else if (notification.isSkippedMoment()) {
            EventEmitter.dispatch(GOOGLE_ONE_TAP_SKIPPED);
        } else if (notification.isDismissedMoment()) {
            EventEmitter.dispatch(GOOGLE_ONE_TAP_DISMISSED);
        } else {
            EventEmitter.dispatch(GOOGLE_ONE_TAP_SHOWN);
        }
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.continueWithNextIdp = continueWithNextIdp;
        }
        return () => {
            if (typeof window !== "undefined") {
                delete window.continueWithNextIdp;
            }
        };
    }, []);
};

export default useGoogleOneTapImpressions;
