import React, { useState, useEffect } from "react";
import retry from "async-retry";
import styled from "styled-components";

import Button from "common/components/Button";
import AuthCard from "common/components/AuthCard";
import { useHistory, useParams } from "react-router-dom";
import PaymentsService from "services/payments.service";
import ErrorIcon from "common/assets/ErrorIcon";
import SuccessIcon from "common/assets/SuccessIcon";
import LoaderIcon from "common/components/LoaderIcon";
import { billingConstants } from "../constants";
import { useQuery } from "@common/utils/common-utils";
import BoxLoader from "@common/components/BoxLoader";
import { EventEmitter } from "@common/events/eventEmitter";
import { PAYMENT_FAILURE, PAYMENT_SUCCESS } from "@common/events/events";

const Wrapper = styled.label`
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
`;

const HeadingWrapper = styled.div`
    margin-bottom: 20px;
    margin-top: 0;
    text-align: center;
`;

const Heading = styled.h2`
    letter-spacing: 1px;
`;

const StyledContent = styled.p`
    font-size: 15px;
    line-height: var(--line-height-28);
    margin-bottom: 30px;
    text-align: center;
`;

const IconWrapper = styled.div`
    text-align: center;
    margin-bottom: 30px;
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const SubText = styled.div`
    font-size: 12px;
`;

function fetchPaymentWithRetry(orgId, stripeInvoiceId) {
    return retry(
        async (bail) => {
            try {
                const response = await PaymentsService.getInvoice(orgId, stripeInvoiceId, false);

                // If its still being processed, retry
                if (["open", "draft"].includes(response.data.status)) {
                    return Promise.reject(response);
                }

                return response.data;
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    return Promise.reject(err);
                }

                // Don't retry for any errors
                bail(err);
            }
        },
        {
            retries: 15,
            factor: 1.25,
            minTimeout: 500,
        },
    );
}

const SUCCESS_MESSAGE = {};
SUCCESS_MESSAGE[billingConstants.ITEM_TYPES.SUBSCRIPTION] =
    "Your payment was successful! You've been moved to the new plan. Any unused credits from the previous paid plan will be added to your account in 2-3 days.";
// Success message for buying a new addOn
SUCCESS_MESSAGE[billingConstants.ITEM_TYPES.ADDON] =
    "Your payment was successful! Extra credits have been added to your account.";

const errorMessage =
    "We were unable to check the status of your payment at this time. If you made a successful payment but did not receive any credits, please contact us at support@pixelbin.io.";

export default function CheckoutFinished() {
    const history = useHistory();
    const { orgId } = useParams();
    const defaultRedirectURL = `/organization/${orgId}/dashboard`;
    const query = useQuery();
    const stripeInvoiceId = query.get("stripeInvoiceId");
    const defaultMessage = query.get("defaultMessage");
    const redirectURL = query.get("redirectUrl");
    const [fetchingStatus, setFetchingStatus] = useState(true);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState();

    if (!stripeInvoiceId && !defaultMessage) history.back();

    useEffect(async () => {
        if (defaultMessage) {
            setSuccess(true);
            setMessage(defaultMessage);
            setFetchingStatus(false);
            return;
        }

        try {
            const payment = await fetchPaymentWithRetry(orgId, stripeInvoiceId);
            if (payment.status === "paid") {
                setSuccess(true);
                setMessage(SUCCESS_MESSAGE[payment.itemType] || "Your payment was successful!");
                EventEmitter.dispatch(PAYMENT_SUCCESS, {
                    payment,
                });
            } else {
                EventEmitter.dispatch(PAYMENT_FAILURE, {
                    payment,
                    message: "Your payment was unsuccessful!",
                });
                setSuccess(false);
            }
        } catch (err) {
            setSuccess(false);
        } finally {
            setFetchingStatus(false);
        }
    }, [stripeInvoiceId]);

    const handleRedirect = () => {
        history.push(redirectURL ? decodeURIComponent(redirectURL) : defaultRedirectURL);
    };

    return fetchingStatus ? (
        <LoadingContainer>
            <LoaderIcon />
            {"Please wait while we fetch your payment status"}
        </LoadingContainer>
    ) : (
        <Wrapper>
            <AuthCard
                data-testid="checkout-finished"
                width="35%"
                margin="50px"
                paddingTopBottom="64px"
            >
                {success ? (
                    <>
                        <HeadingWrapper>
                            <IconWrapper>
                                <SuccessIcon />
                            </IconWrapper>
                            <Heading>Congratulations!</Heading>
                            <br />
                        </HeadingWrapper>
                        <StyledContent>{message}</StyledContent>
                        <Button onClick={handleRedirect} width="100%">
                            Continue
                        </Button>
                    </>
                ) : (
                    <>
                        <HeadingWrapper>
                            <IconWrapper>
                                <ErrorIcon />
                            </IconWrapper>
                            <Heading>Error</Heading>
                            <br />
                        </HeadingWrapper>
                        <StyledContent>{errorMessage}</StyledContent>
                        <Button onClick={handleRedirect} width="100%">
                            Continue
                        </Button>
                    </>
                )}
            </AuthCard>
        </Wrapper>
    );
}
