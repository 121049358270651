import environment from "../../environment";
import ApiService from "../../services/api.service";

// @deprecated
/* istanbul ignore next */
export const fetchWithHeaders = (url, payload) => {
    let axiosOptions = {
        method: payload.method,
        url,
        data: payload.body,
    };

    if (payload.responseType) {
        axiosOptions["responseType"] = payload?.responseType;
    }

    return new Promise(async (resolve, reject) => {
        try {
            const res = await ApiService.axios(axiosOptions);
            if (res.status === 200) {
                resolve(await res.data);
            } else {
                reject(await res);
            }
        } catch (error) {
            reject(error);
        }
    });
};

// @deprecated
/* istanbul ignore next */
export const getPricingPlans = (orgId) => {
    return fetchWithHeaders(`${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/payment/plans`, {
        method: "GET",
    });
};

// @deprecated
/* istanbul ignore next */
export const getTopups = (orgId) => {
    return fetchWithHeaders(`${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/payment/topups`, {
        method: "GET",
    });
};

// @deprecated
/* istanbul ignore next */
export const attachAddressToCustomer = (orgId, checkoutForm) => {
    return fetchWithHeaders(`${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/payment/customers`, {
        method: "PUT",
        body: JSON.stringify({
            line1: checkoutForm.billingAddress,
            city: checkoutForm.city,
            state: checkoutForm.state,
            postal_code: checkoutForm.zipCode,
            country: checkoutForm.country.identifier,
            name_on_card: checkoutForm.nameOnCard,
        }),
    });
};

// @deprecated
/* istanbul ignore next */
export const createSubscription = (orgId, payment_method_id, price_id, coupon_code) => {
    return fetchWithHeaders(
        `${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/payment/subscriptions`,
        {
            method: "POST",
            body: JSON.stringify({
                payment_method_id,
                price: price_id,
                coupon_code,
            }),
        },
    );
};

// @deprecated
/* istanbul ignore next */
export const cancelSubscription = (orgId) => {
    return fetchWithHeaders(
        `${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/payment/subscriptions`,
        {
            method: "PATCH",
        },
    );
};

// @deprecated
/* istanbul ignore next */
export const createInvoices = (orgId, price, coupon_code) => {
    return fetchWithHeaders(`${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/payment/invoices`, {
        method: "POST",
        body: JSON.stringify({
            price,
            quantity: 1,
            coupon_code,
        }),
    });
};

// @deprecated
/* istanbul ignore next */
export const init = (orgId) => {
    return fetchWithHeaders(`${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/user/init`, {
        method: "GET",
    });
};

// @deprecated
/* istanbul ignore next */
export const getCredits = (orgId) => {
    return fetchWithHeaders(`${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/user/credits`, {
        method: "GET",
    });
};

// @deprecated
/* istanbul ignore next */
export const getApiKey = (orgId) => {
    return fetchWithHeaders(
        `${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/user/company/apikey`,
        {
            method: "GET",
        },
    );
};

// @deprecated
/* istanbul ignore next */
export const reIssueApiKey = () => {
    return fetchWithHeaders(`${environment.KAKASHI_MAIN_URL}/api/v1/user/company/apikey`, {
        method: "PATCH",
    });
};

/* istanbul ignore next */
export const getPaymentHistory = (orgId) => {
    return fetchWithHeaders(`${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/user/orders`, {
        method: "GET",
    });
};

// @deprecated
/* istanbul ignore next */
export const upgradeOrDowngradePlan = (orgId, price_id) => {
    return fetchWithHeaders(
        `${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/payment/change-plan`,
        {
            method: "PUT",
            body: JSON.stringify({
                price: price_id,
            }),
        },
    );
};

// @deprecated
/* istanbul ignore next */
export const getOriginalImage = (orgId, token) => {
    const api = `${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/core/original/${token}`;

    return fetchWithHeaders(api, {
        method: "GET",
    });
};

// @deprecated
/* istanbul ignore next */
export const validateCoupon = (orgId, coupon_code, price_id, coupon_type) => {
    const queryParams = "?" + new URLSearchParams({ coupon_code, price_id, coupon_type });
    return fetchWithHeaders(
        `${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/payment/validate-coupon` + queryParams,
        {
            method: "GET",
        },
    );
};

// @deprecated
/* istanbul ignore next */
export const getColors = () => {
    return fetchWithHeaders(`${environment.KAKASHI_MAIN_URL}/api/v1/custom_background/color`, {
        method: "GET",
    });
};

// @deprecated
/* istanbul ignore next */
export const getImages = () => {
    return fetchWithHeaders(`${environment.KAKASHI_MAIN_URL}/api/v1/custom_background/image`, {
        method: "GET",
    });
};

// @deprecated
/* istanbul ignore next */
export const changePaymentMethod = (orgId, source) => {
    return fetchWithHeaders(`${environment.KAKASHI_PNL_URL}/v1.0/org/${orgId}/payment/cards`, {
        method: "POST",
        body: JSON.stringify({
            source,
        }),
    });
};

// @deprecated
/* istanbul ignore next */
export const getCurrentPaymentMethod = () => {
    return fetchWithHeaders(`${environment.KAKASHI_MAIN_URL}/api/v1/payment/cards`, {
        method: "GET",
    });
};

// @deprecated
/* istanbul ignore next */
export const getImage = (url) => {
    const fd = new FormData();
    fd.append("image_url", url);

    return fetchWithHeaders(`${environment.KAKASHI_MAIN_URL}/api/v1/core/get-image`, {
        method: "POST",
        body: fd,
        responseType: "blob",
    });
};
