export const SegmentAction = {
    InitiateSegmentScipt: "INITIATE_SEGMENT_SCRIPT",
};

export const initiateScript = (data, dispatch) => {
    dispatch({
        type: SegmentAction.InitiateSegmentScipt,
        payload: data,
    });
};
