import React, { createContext, useContext, useState } from "react";

const ResizeContext = createContext({
    showResizePortal: false,
    setShowResizePortal: () => {},
    showBulkTransformPanel: false,
    closeResizePortal: () => {},
});

const initialResizeStatus = {
    onChange: () => {}, // call to update input
};

const ResizeContextProvider = ({ children }) => {
    const [previewTab, setPreviewTab] = useState("original");
    const [showResizePortal, setShowResizePortal] = useState(false); // manage resize portal
    const [imageSrc, setImageSrc] = useState(null);
    const [resizeStatus, setResizeStatus] = useState(initialResizeStatus); // manage resized input values
    const [gizmo, setGizmo] = useState({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    }); // wrapper around image
    const [resizedDimensions, setResizedDimensions] = useState("");
    const [scaledImage, setScaledImage] = useState({ width: 0, height: 0 });

    const closeResizePortal = () => {
        setShowResizePortal(false);
    };

    return (
        <ResizeContext.Provider
            value={{
                showResizePortal,
                setShowResizePortal,
                closeResizePortal,
                resizeStatus,
                setResizeStatus,
                gizmo,
                setGizmo,
                resizedDimensions,
                setResizedDimensions,
                scaledImage,
                setScaledImage,
                imageSrc,
                setImageSrc,
                previewTab,
                setPreviewTab,
            }}
        >
            {children}
        </ResizeContext.Provider>
    );
};

const useResizeContext = () => useContext(ResizeContext);

export { ResizeContextProvider, useResizeContext };
