import { getRedirectUrl } from "@common/utils/common-utils";
import URLS from "../common/utils/apiUrls";
import ApiService from "./api.service";
import { isMobile } from "react-device-detect";

const AuthService = {
    register(formData, stateSearchParam) {
        let axiosOptions = Object.assign(
            {},
            { data: formData },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(`${URLS.registerUser}?${stateSearchParam}`, axiosOptions);
    },

    login(formData) {
        let axiosOptions = Object.assign(
            {},
            { data: formData },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.loginUser, axiosOptions);
    },

    setFreePropertiesCookie(tokenID, domainToSet) {
        let axiosOptions = {
            params: {
                tokenID,
            },
        };
        return ApiService.post(domainToSet, axiosOptions);
    },

    forgotPassword(formData) {
        let axiosOptions = Object.assign(
            {},
            { data: formData },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.forgotPassword, axiosOptions);
    },

    resetPassword(formData) {
        let axiosOptions = Object.assign(
            {},
            { data: formData },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.resetPassword, axiosOptions);
    },

    verifyEmail(code, sessionState) {
        let axiosOptions = Object.assign(
            {},
            { data: { code } },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(
            `${URLS.verifyEmail}?state=${JSON.stringify(sessionState)}`,
            axiosOptions,
        );
    },

    loginViaGoogle(data) {
        let axiosOptions = Object.assign(
            {},
            { data },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.googleLogin, axiosOptions);
    },

    logout() {
        let axiosOptions = Object.assign(
            {},
            {},
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.logoutUser, axiosOptions);
    },

    updateProfile(updateOpts) {
        const axiosOptions = {
            data: updateOpts,
        };
        return ApiService.patch(URLS.profileDetails, axiosOptions);
    },

    updateEmail(updateOpts) {
        const axiosOptions = {
            data: updateOpts,
        };
        return ApiService.post(URLS.updateEmailInit, axiosOptions);
    },

    verifyEmailUpdate(code) {
        return ApiService.post(URLS.updateEmailComplete, {
            data: { code },
        });
    },

    updatePassword(newPassword) {
        return ApiService.post(URLS.updatePassword, { data: { password: newPassword } });
    },

    updateMeta(updateOpts) {
        return ApiService.patch(URLS.updateMeta, { data: updateOpts });
    },
};

export default AuthService;
