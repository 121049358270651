import React from "react";
import styled from "styled-components";
import { Breakpoints } from "../../GlobalStyle";
import { PublicPropertiesColor } from "../global-styled-components";

const StyledLink = styled.a`
    color: ${(p) => (p.color ? p.color : "var(--primary-light)")} !important;
    ${PublicPropertiesColor}
    cursor: pointer;
    font-size: var(--body-2-d);
    font-weight: normal;
    display: inline-flex;

    &:hover {
        text-decoration: underline;
    }

    @media (max-width: ${Breakpoints.mobile}px) {
        font-size: var(--body-2-m);
    }
`;

export default function CustomLink({ children, openInNew = false, ...restProps }) {
    const openInNewProps = {};

    if (openInNew) {
        openInNewProps.rel = "noopenner noreferrer";
        openInNewProps.target = "_blank";
    }

    return (
        <StyledLink {...restProps} {...openInNewProps}>
            {children}
        </StyledLink>
    );
}
