import React from "react";
import styled from "styled-components";
import { publicPropButtonThemes, btnStyleName } from "../constants/theme.constants";

const BaseButton = styled.button`
    border-radius: var(--border-radius-8);
    color: var(--white);
    font-family: var(--body-font);
    font-style: normal;
    overflow: hidden;
    position: relative;
    display: ${(props) => (props.truncateText ? "unset" : "flex")};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    width: ${(props) => props.width};
    margin-left: ${({ marginLeft }) => marginLeft};
    margin-right: ${({ marginRight }) => marginRight};
    margin-top: ${({ marginTop }) => marginTop};
    white-space: ${({ truncateText }) => truncateText && "nowrap"};
    text-overflow: ${({ truncateText }) => truncateText && "ellipsis"};
    letter-spacing: ${({ size }) =>
        size === "default"
            ? "var(--letter-spacing-1)"
            : size === "small"
            ? "var(--letter-spacing-15)"
            : "var(--letter-spacing-1)"};
    line-height: 120%;
    padding: ${({ size }) =>
        size === "default" ? "0 24px" : size === "small" ? "0 16px" : "0 40px"};
    height: ${({ size }) => (size === "default" ? "48px" : size === "small" ? "40px" : "56px")};
    font-size: ${({ size }) =>
        size === "default"
            ? "var(--button)"
            : size === "small"
            ? "var(--button-small)"
            : "var(--button)"};
    @media (max-width: 767px) {
        font-size: var(--body-3-d);
        // in mobile button size will always be small
        font-size: var(--button-small);
        height: 40px;
        padding: 0 16px;
    }
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`;

const CtaButton = styled(BaseButton)`
    background: var(--brand-gradient);
    :hover {
        background: var(--brand-gradient-reverse);
    }
`;

const PrimaryButton = styled(BaseButton)`
    background: ${(p) =>
        p.theme.utmSource && publicPropButtonThemes[p.theme.utmSource]
            ? `var(${publicPropButtonThemes[p.theme.utmSource].gradient})`
            : "var(--primary)"};
    :hover {
        background: ${(p) =>
            p.theme.utmSource && publicPropButtonThemes[p.theme.utmSource]
                ? `var(${publicPropButtonThemes[p.theme.utmSource].gradient}-reverse)`
                : "var(--primary-hover)"};
    }
`;

const SecondaryButton = styled(BaseButton)`
    background: var(--dark-700);
    :hover {
        background: ${(props) => (props.hoverTransparent ? "" : "var(--dark-600)")};
    }
`;

const TertiaryButton = styled(BaseButton)`
    /* background: rgba(255, 255, 255, 0); */
    // change back if any issue spotted
    background: var(--tertiary);
    border: 1px solid var(--border-light);
    :hover {
        background: var(--light-bg-1-hover);
        border: 1px solid var(--dark-600);
    }
`;

const DangerButton = styled(BaseButton)`
    background: var(--danger);
    :hover {
        background: var(--danger-hover);
    }
`;

const TextButton = styled(BaseButton)`
    background-color: transparent;
`;

const OutLineButton = styled(BaseButton)`
    background: transparent;
    border: ${(props) => `1px solid var(${btnStyleName?.[props.mode]?.bg})`};
    color: ${(props) => `var(${btnStyleName?.[props.mode]?.bg})`};
    :hover {
        background: ${(props) => `var(${btnStyleName?.[props.mode]?.bg})`};
        color: ${(props) => (props.hoverClr ? `var(${props.hoverClr})` : "inherit")};
    }
`;

const normalBtnCase = ({
    children,
    mode, // cta, primary, secondary, tertiary, danger
    size = "default", // default, small, large
    variant = "normal", // normal, outline
    ...props
}) => {
    switch (mode) {
        case "cta":
            return (
                <PrimaryButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </PrimaryButton>
            );
        case "primary":
            return (
                <PrimaryButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </PrimaryButton>
            );
        case "secondary":
            return (
                <SecondaryButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </SecondaryButton>
            );
        case "tertiary":
            return (
                <TertiaryButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </TertiaryButton>
            );
        case "danger":
            return (
                <DangerButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </DangerButton>
            );
        case "text":
            return (
                <TextButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </TextButton>
            );

        default:
            return (
                <PrimaryButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </PrimaryButton>
            );
    }
};

const outlineSwitchCase = ({
    children,
    mode, // cta, primary, secondary, tertiary, danger
    size = "default", // default, small, large
    ...props
}) => {
    return (
        <OutLineButton data-testid="sample-btn" size={size} {...props} mode={mode}>
            {children}
        </OutLineButton>
    );
};

const Button = ({
    variant = "normal", // normal, outline
    ...props
}) => {
    switch (variant) {
        case "normal":
            return normalBtnCase(props);

        case "outline":
            return outlineSwitchCase(props);

        default:
            return normalBtnCase(props);
    }
};

export default Button;
