import React from "react";
import styled from "styled-components";

const StyledCard = styled.div`
    position: relative;
    padding: ${(props) =>
        `${props.paddingTopBottom || "24px"} ${props.paddingLeftRight || "24px"}`};
    color: var(--white-90);
    width: ${(props) => (props.width ? props.width : "auto")};
    height: ${(props) => (props.height ? props.height : "100%")};
    /* background: rgba(255, 255, 255, 0.04); */
    border-radius: 10px;
    margin: ${(props) => (props.margin ? props.margin : "auto")};
    min-height: ${(props) => props.minHeight};
    border: ${(props) => props.border};
`;

const Card = ({ children, ...restProps }) => {
    return (
        <StyledCard data-testid="card" {...restProps}>
            {children}
        </StyledCard>
    );
};

export default Card;
